import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { reset } from "../../Utils/Features/librarySlice";
import { getApiUrl } from "../../helperFunctions/envVars";
import { pushToDataLayer } from "../../helperFunctions/gtm";

const apiUrl = getApiUrl();
const apiRoot = apiUrl + "/api";

export const getHeaders = () => {
  return {
    withCredentials: true,
    xsrfHeaderName: "X-CSRFTOKEN",
    xsrfCookieName: "csrftoken",
  };
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ dispatch, body }, thunkAPI) => {
    dispatch(reset(thunkAPI.getState()));
    let result;
    if (body) {
      result = await axios.post(`${apiRoot}/auth/login`, body, getHeaders());
    }
    if (result.status === 200) {
      const userId = result.data.user._id;
      const user = result.data.user;

      // Track login event with environment automatically included
      pushToDataLayer('login', {
        user: 'logged-in',
        method: 'email', // Additional event data
      });

      //TODO check status of user -> Status (free, trial, payed (monthly, yearly, lieftime))
      // update profile according with state (colour of informative, in profile or under 'Subscription/payment?')

      return { userId, user };
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await axios.post(`${apiRoot}/auth/logout`, {}, getHeaders());
});

export const AuthenticationStatus = {
  NotAuthenticated: "notAuthenticated",
  Pending: "pending",
  Authenticated: "authenticated",
  Failed: "failed",
  Expired: "expired",
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    userId: null,
    userData: null,
    authenticationStatus: AuthenticationStatus.NotAuthenticated,
  },
  reducers: {
    resetAuth: (state) => {
      if (state.authenticationStatus === AuthenticationStatus.Authenticated) {
        state.authenticationStatus = AuthenticationStatus.Expired;
      }
    },
    frontEndLogout: (state) => {
      if (state.authenticationStatus === AuthenticationStatus.Expired) {
        state.authenticationStatus = AuthenticationStatus.NotAuthenticated;
      }
    },
    subscriptionChecked: (state) => {
      state.userData = state.userData || {};
      state.userData.profile = state.userData.profile || {};
      state.userData.profile.trialStarted = undefined;
      state.userData.profile.trialEnded = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.authenticationStatus = AuthenticationStatus.Pending;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.authenticationStatus = AuthenticationStatus.Authenticated;

        state.userId = action.payload.userId;
        state.userData = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.authenticationStatus = AuthenticationStatus.Failed;

        state.userId = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.authenticationStatus = AuthenticationStatus.NotAuthenticated;

        state.userId = null;
        state.userData = null;
      })
      .addCase(logout.rejected, (state) => {
        if (state.authenticationStatus === AuthenticationStatus.Expired) {
          // if logout is rejected because the authentication was already expired just set not authenticated
          state.authenticationStatus = AuthenticationStatus.NotAuthenticated;

          state.userId = null;
          state.userData = null;
        } else {
          // ???
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { frontEndLogout, resetAuth, subscriptionChecked } =
  authSlice.actions;

export default authSlice.reducer;

export const checkIsFreeSubscription = (state) => {
  // if profile is loaded it contains fresher data use that, otherwise the userdata received from login
  if (state.profileSliceReducer?.profileData) {
    return state.profileSliceReducer?.profileData.subscriptionType === "free";
  } else {
    return state.auth.userData.profile.subscriptionType === "free";
  }
};
