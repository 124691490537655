// customLogger.js

import { isDevelopment } from "./envVars";

/**
 * Custom logger function that logs the message and name-value pairs of the arguments.
 * This function only logs when the environment variable REACT_APP_NODE_ENV is set to 'DEV'.
 * Usage: customLog(message)
 * - message: The main message to log, in the form `What needs to be logge name:${arg1}, userId:${arg2} ...` OR 'message ${JSON.stringify(value)}', arg1, arg2,
 *
 * @param {string} args - The main message to log, if you want to pass literals: ${value} -> pass them like this please:  ${JSON.stringify(value)}.
 */
export function customLog(...args) {
  if (isDevelopment()) {
    const formattedArgs = args.map((arg) =>
      typeof arg === "object" ? JSON.stringify(arg) : arg
    );
    console.log(...formattedArgs);
  }
}
  