// AmazonConnector.js
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { restartSyncProcess } from "../../Utils/Features/amazonSyncSlice";

import { store } from "../../Utils/Store/Store";
import { customLog } from "../../helperFunctions/customLogger";
import { loadExternalScript } from "../../helperFunctions/loadExternalScript";

class AmazonConnector {
  static async authenticate() {
    return new Promise(async (resolve, reject) => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        if (user) {
          if (!user.username.includes("amazon")) {
            // federatedSignIn opens the amplify login page and leaves DeepRead app so
            // the sync process is aborted and flagged to be continued when user returns to app
            store.dispatch(restartSyncProcess(true));
            await Auth.federatedSignIn({ provider: "LoginWithAmazon" });
            // return null to signify user was not amazon authenticated and federated sign in is started
            resolve(null);
          }
        }

        resolve(user);
      } catch (error) {
        console.error("Authentication error:", error);

        // Optionally, dispatch an action to handle the error in your application
        //store.dispatch(handleAuthenticationError());

        // Show a toast to the user with an option to log out
        toast.error(
          <div>
            Authentication failed. Please <strong>log out</strong> and try
            again.
          </div>,
          { position: toast.POSITION.TOP_RIGHT, autoClose: false }
        );

        // Reject the promise so that the calling code can handle the error
        reject(error);
      }
    });
  }

  // Get the current authenticated user's credentials
  static async getCurrentUserCredentials() {
    try {
      const credentials = await Auth.currentCredentials();
      customLog(`User credentials: , ${JSON.stringify(credentials)}`);
      // Access the credentials properties such as accessKeyId, secretAccessKey, sessionToken, etc.
    } catch (error) {
      console.error("Error retrieving user credentials:", error);
    }
  }

  static async getTokenAndSession() {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    return [accessToken.getJwtToken(), session];
  }

  static async disconnect() {
    await Auth.signOut();
  }

  static async authenticateFlowAndTriggerExtension(user) {
    await AmazonConnector.getCurrentUserCredentials();
    const aws_array = await AmazonConnector.getTokenAndSession();
    let aws_auth_token = aws_array[0];
    let session = aws_array[1];

    loadExternalScript(
      "https://api-cdn.amazon.com/sdk/login1.js",
      function () {}
    );

    // const scriptElement = document.createElement('script');
    // scriptElement.src = 'https://api-cdn.amazon.com/sdk/login1.js';
    // document.head.appendChild(scriptElement);

    customLog(
      "authenticateFlowAndTriggerExtension, should be an amazon user",
      session,
      user
    );

    const { userId } = store.getState().auth;
    customLog(`before calling Sync-Books => userId: ${userId}`);

    // Make sure aws_auth_token is not null before proceeding
    if (aws_auth_token) {
    } else {
      console.log("Error retrieving aws_auth_token");
      //throw Error("Error retrieving aws_auth_token");
    }
  }
  catch(error) {
    console.log("Error signing in with Amazon:", error);
    // throw Error("Error signing in with Amazon:", error);
  }
}

export default AmazonConnector;
