import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FeedDetailsCard from "../../components/FeedDetails/FeedDetailsCard/FeedDetailsCard";
import { feed, incrementPage } from "../../Utils/Features/feedSlice";
import { Virtuoso } from "react-virtuoso";
import FilterSummary from "../../components/Common/FilterSummary";
import { Loading } from "../../components/Loading";
import { ExpandBox } from "../../components/Common/ExpandBox";

const MyFeed = () => {
  const dispatch = useDispatch();
  const curElem = useRef(null);

  const { feedList, paginationInfo, isLoading } = useSelector(
    (state) => state.feed
  );
  const { feedFilter: filter } = useSelector((state) => state.userConfig);

  const [hasMore, setHasMore] = useState(true);
  const currentPage = paginationInfo?.currentPage;
  const totalPages = paginationInfo?.totalPages;
  const totalItems = paginationInfo?.totalItems;

  useEffect(() => {
    if (currentPage >= totalPages) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [currentPage, totalPages, totalItems]);

  // Get more data if value of filter changes
  useEffect(() => {
    dispatch(feed(filter));
  }, [dispatch, filter]);

  // Fetch more data on infinite scroll
  const loadMore = () => {
    if (hasMore) {
      dispatch(incrementPage());
      dispatch(feed(filter));
    }
  };

  if (feedList?.length === 0 && isLoading) {
    return (
      <div className="feedParentContainer">
        <Loading />
      </div>
    );
  }

  if (totalItems === 0) {
    return (
      <div className="feedParentContainer mt-[32px] text-[length:var(--font-large)] !px-3">
        <FilterSummary
          title="No feed elements found, with the selected filter:"
          filter={filter}
        />
      </div>
    );
  }

  return (
    <div className="feedParentContainer feedWrapperOverflow">
      <div className="feedBoxLayout">
        <Virtuoso
          totalCount={feedList?.length}
          endReached={loadMore}
          overscan={{ main: 800, reverse: 800 }}
          // TODO I might have overdone the styles.. but it should cover most browsers
          style={{
            overflowY: "scroll", // Enable scrolling within the Virtuoso component
            scrollbarWidth: "none", // Hide the scrollbar for Firefox
            msOverflowStyle: "none", // Hide the scrollbar for Internet Explorer/Edge
            WebkitOverflowScrolling: "touch", // Enable smooth scrolling on iOS devices
            WebkitScrollbar: {
              display: "none", // Hide the scrollbar for Chrome
            },
          }}
          itemContent={(index) => {
            const data = feedList[index];
            return (
              <div
                key={index}
                id={`feed${index}`}
                ref={
                  localStorage.getItem("feedCurrentBookId") === `feed${index}`
                    ? curElem
                    : null
                }
              >
                <FeedDetailsCard feedDataList={data} bookId={`feed${index}`} />
              </div>
            );
          }}
          components={{
            Header: () => {
              const filterExists = !Object.values(filter.filter).every(
                (value) => value.length === 0
              );
              if (filterExists) {
                return (
                  <div className="feedDetailsCard flex justify-center">
                    <ExpandBox collapsedHeight={80}>
                      <FilterSummary
                        title="Filter settings used for the feed:"
                        filter={filter}
                      />
                    </ExpandBox>
                  </div>
                );
              } else {
                return <></>;
              }
            },
            Footer: () => {
              if (!hasMore) {
                return (
                  <div className="feedDetailsCard flex justify-center">
                    <FilterSummary
                      title="No more feed elements, with the selected filter:"
                      filter={filter}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            },
          }}
        />
      </div>
    </div>
  );
};

export default MyFeed;
