import React, { useEffect, useRef, useState } from "react";
import "./profile.css";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProfile,
  getUserEmail,
  putProfileData,
} from "../../Utils/Features/profileSlice.js";
import toastMessage, { toastType } from "../../helperFunctions/toastMessage.js";
import Skeleton from "@mui/material/Skeleton";
import LightModeIcon from "@mui/icons-material/LightMode";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import PublicIcon from "@mui/icons-material/Public";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import {
  colorThemeEnum,
  setcurrentMode,
} from "../../Utils/Features/lightDarkModeSlice.js";
import S3Storage from "../S3storage/s3storage.js";
import { handleLogout } from "../../helperFunctions/global.js";
import { ApiStatus } from "../../Utils/Features/ApiStatus.js";

import {
  getNumericEnvVariable,
  isDevelopment,
} from "../../helperFunctions/envVars.js";
import { ProfileImage } from "./ProfileImage.jsx";
import { checkIsFreeSubscription } from "../../Utils/Features/authSlice.js";
import { Refresh } from "../Common/RefreshIcon/RefreshIcon.jsx";

const s3 = new S3Storage();

const Profile = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();

  const { profileData, profileStatus, userEmail } = useSelector(
    (state) => state?.profileSliceReducer
  );

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  const [userProfileName, setUserProfileName] = useState(
    profileData?.profileData?.username
  );

  const [userFirstName, setUserFirstName] = useState(
    profileData?.profileData?.firstname
  );

  const [userProfileEmail, setUserProfileEmail] = useState(
    profileData?.profileData?.alternativeEmail
  );

  const [errorMessage, setErrorMessage] = useState({
    errorFirstName: "",
    errorName: "",
    errorEmail: "",
  });

  const [loading, setLoading] = useState(false);

  const isFreeSubscription = useSelector((state) =>
    checkIsFreeSubscription(state)
  );

  useEffect(() => {
    if (profileStatus !== ApiStatus.Fulfilled) dispatch(fetchProfile());
    dispatch(getUserEmail());
    // we want this effect to run only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileData) {
      setUserProfileName(profileData?.username);
      setUserProfileEmail(profileData?.alternativeEmail);
      setUserFirstName(profileData?.firstname);
    }
  }, [profileData, lightDarkMode]);

  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      if (e.target.value.trim().length <= 0) {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          errorFirstName: "Please Enter the Firstname",
        }));
        setUserFirstName(e.target.value.trim());
      } else {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          errorFirstName: "",
        }));
        setUserFirstName(e.target.value.trim());
      }
    }
    if (e.target.name === "userName") {
      if (e.target.value.trim().length <= 0) {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          errorName: "Please Enter the Username",
        }));
        setUserProfileName(e.target.value.trim());
      } else {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          errorName: "",
        }));
        setUserProfileName(e.target.value.trim());
      }
    }
    if (e.target.name === "userEmail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (e.target.value.trim().length <= 0) {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          errorEmail: "Please Enter the Email",
        }));
        setUserProfileEmail(e.target.value.trim());
      } else if (!emailRegex.test(e.target.value.trim())) {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          errorEmail: "Invalid email format.",
        }));
        setUserProfileEmail(e.target.value.trim());
      } else {
        setErrorMessage((prevErrors) => ({
          ...prevErrors,
          errorEmail: "",
        }));
        setUserProfileEmail(e.target.value.trim());
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.type === "blur") {
      event.preventDefault(); // prevent the default form submission
      submitData();
    }
  };

  const privacyHandler = (e) => {
    const isChecked = e.target.checked;
    submitData(isChecked);
  };

  const submitData = (isPublic) => {
    const data = {
      firstname: userFirstName,
      username: userProfileName,
      alternativeEmail: userProfileEmail,
    };
    // update public field only if it has changed to avoid refetch for the library in librarySlice
    // check .addCase(putProfileData.fulfilled...
    if (isPublic !== null && isPublic !== undefined) {
      data["public"] = isPublic;
    }
    if (
      errorMessage?.errorName?.length > 0 ||
      errorMessage?.errorEmail?.length > 0 ||
      errorMessage?.errorFirstName?.length > 0
    ) {
      toastMessage("error", 3000, "Please add Data");
      return;
    }

    dispatch(putProfileData({ data }));
  };

  const handleChangeLightMode = (e) => {
    const isChecked = e.target.checked;
    dispatch(
      setcurrentMode(
        isChecked ? `${colorThemeEnum?.DARK}` : `${colorThemeEnum?.LIGHT}`
      )
    );
  };

  function handleUpload(event, type) {
    let selectedFile;
    selectedFile = event.target.files[0];
    const maxSize = getNumericEnvVariable(
      "REACT_APP_IDEA_IMAGE_MAX_SIZE",
      10485760
    );

    if (selectedFile) {
      // Check file size
      if (selectedFile.size > maxSize) {
        toastMessage(
          "error",
          5000,
          "File size exceeds 10 MB. Choose a smaller file."
        );

        return;
      }

      // Check file type
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(selectedFile.type)) {
        toastMessage(
          "error",
          5000,
          "Invalid file type. Please select a PNG, JPG, or JPEG."
        );
        return;
      }
      setLoading(true);

      // Generate a unique key for the S3 object (e.g., using a timestamp, idea._id)
      const key = `${userProfileName}_${selectedFile.name}`;

      // Upload the file to S3
      s3.putObject(key, selectedFile)

        .then((data) => {
          const oldPictureLink = profileData?.profileImageUrl;

          const imgData = {
            profileImageUrl: key,
          };

          dispatch(putProfileData({ data: imgData }))
            .then(({ payload }) => {
              const isSuccessStatus =
                payload?.status === 200 || payload?.status === 201;
              if (isSuccessStatus) {
                toastMessage(
                  "success",
                  3000,
                  "Profile image updated successfully,"
                );
              }
            })
            .catch((error) => {
              console.error("Error uploading image:", error);
              setLoading(false); // Set loading state to false if there's an error.
            });

          setLoading(false);
          // need to clear the file input file name otherwise
          // upload picture file - paste picture - upload the just uploaded file again
          // -scenario will not work because the input element remembers the name of the file that it last uploaded
          fileInputRef.current.value = "";
          if (s3.isS3Link(oldPictureLink)) {
            if (
              oldPictureLink &&
              oldPictureLink.length > 0 &&
              oldPictureLink !== key
            ) {
              s3.deleteObject(oldPictureLink);
            }
          }
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          setLoading(false); // Set loading state to false if there's an error.
        });
    }
  }

  const refresh = () => {
    dispatch(fetchProfile());
  };

  return (
    <div>
      <div></div>
      <div className="profile-section mt-10 px-[16px]">
        {/* top */}
        <div className="flex justify-between items-center max-w-[670px] mx-auto  md:px-0 px-3 profile-wrapper">
          <div className="left relative">
            <div className="img-wrapper ">
              {profileStatus === "loading" && loading ? (
                <Skeleton variant="circular" width={150} height={150} />
              ) : (
                <span>
                  <span
                    onClick={() => {
                      fileInputRef.current?.click();
                    }}
                  >
                    <ProfileImage />
                  </span>

                  <div className="pb-2 pl-2">
                    <Refresh
                      onClick={() => {
                        refresh();
                      }}
                    />
                  </div>
                </span>
              )}

              <label className=" upload-file absolute right-[7px] bottom-[0px] rounded-[50%] p-2 mb-0 text-xl w-[40px] h-[40px] flex items-center justify-center">
                <EditIcon htmlFor="update-profile" className="cursor-pointer" />
                <input
                  type="file"
                  id="update-profile"
                  className="hidden"
                  name="image"
                  accept=".jpg, .jpeg, .png"
                  ref={fileInputRef}
                  onChange={handleUpload}
                />
              </label>
            </div>
          </div>
          <div className="right flex flex-col justify-between h-[180px] profile-wrapper-inner">
            <div className="flex items-center gap-4 relative ml-auto mt-2">
              <label
                htmlFor="first_name"
                className="block font-medium text-right  mb-0 text-xl whitespace-nowrap textPrimary"
              >
                First name
              </label>
              <input
                type="text"
                id="first_name"
                className="w-[300px] bg-gray-50 border border-gray-300 text-gray-900 text-xl h-[30px]
                           focus:ring-blue-500 focus:border-blue-500 block p-2.5 rounded-full placeholder:text-xl"
                placeholder="First name"
                name="firstName"
                value={userFirstName}
                onChange={(e) => {
                  handleChange(e);
                }}
                onKeyDown={handleKeyDown}
                onBlur={handleKeyDown}
                required
              />
              {errorMessage.errorFirstName.length > 0 && (
                <p className="absolute bottom-[-15px] left-[118px] text-red-500 text-xs">
                  {errorMessage.errorFirstName}
                </p>
              )}
            </div>
            <div className="flex items-center gap-4 relative ml-auto mt-2">
              <label
                htmlFor="first_name"
                className="block font-medium  text-right mb-0 text-xl whitespace-nowrap textPrimary"
              >
                User name
              </label>
              <input
                type="text"
                id="first_name"
                className="w-[300px] bg-gray-50 border border-gray-300 text-gray-900 text-xl h-[30px]
                           focus:ring-blue-500 focus:border-blue-500 block p-2.5 rounded-full placeholder:text-xl"
                placeholder="User name"
                value={userProfileName}
                name="userName"
                onChange={(e) => {
                  handleChange(e);
                }}
                onKeyDown={handleKeyDown}
                onBlur={handleKeyDown}
                required
              />
              {errorMessage.errorName.length > 0 && (
                <p className="absolute bottom-[-15px] left-[118px] text-red-500 text-xs">
                  {errorMessage.errorName}
                </p>
              )}
            </div>
            <div className="flex items-center gap-4 ml-auto relative mt-2">
              <label
                htmlFor="first_name"
                className="block font-medium text-right text-xl whitespace-nowrap textPrimary"
              >
                Email
              </label>
              <input
                type="text"
                id="first_name"
                className="w-[300px] bg-gray-50 border border-gray-300 text-gray-900 text-xl h-[30px]
                           focus:ring-blue-500 focus:border-blue-500 block p-2.5 rounded-full placeholder:text-xl"
                placeholder="UserEmail"
                value={userEmail}
                name="user email"
                disabled
              />
            </div>
            <div className="flex items-center gap-4 ml-auto relative mt-2">
              <label
                htmlFor="first_name"
                className="block font-medium text-right text-xl whitespace-nowrap textPrimary"
              >
                Alt. Email
              </label>
              <input
                type="text"
                id="first_name"
                className="w-[300px] bg-gray-50 border border-gray-300 text-gray-900 text-xl h-[30px]
                           focus:ring-blue-500 focus:border-blue-500 block p-2.5 rounded-full placeholder:text-xl"
                placeholder="User email"
                value={userProfileEmail}
                name="userEmail"
                onChange={(e) => {
                  handleChange(e);
                }}
                onKeyDown={handleKeyDown}
                onBlur={handleKeyDown}
                required
              />
              {errorMessage.errorEmail.length > 0 && (
                <p className="absolute bottom-[-19px] left-[164px] text-red-500 text-xs">
                  {errorMessage.errorEmail}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* mid-section */}
        <div className="mt-24  flex justify-center items-center flex-col ml-[104px] mid-section md:px-0 px-3">
          <table className="table-auto">
            <tbody>
              <tr className="">
                <td className="py-3 text-right">
                  {" "}
                  <p
                    className={`text-xl mr-[18px] desktop optionText ${
                      !profileData?.public && "optionBorder"
                    }
                          ${isFreeSubscription && "disabled"}
                        `}
                  >
                    Private profile
                  </p>
                  <p
                    className={` text-xl mobile  mr-[20px] inline-block  optionText ${
                      isFreeSubscription && "disabled"
                    }`}
                  >
                    {!profileData?.public ? (
                      <>Private profile</>
                    ) : (
                      <>Public profile</>
                    )}
                  </p>
                </td>
                <td className="py-3">
                  {" "}
                  <div className="switch">
                    <input
                      id="public"
                      className="switch__input"
                      name="switch"
                      type="checkbox"
                      onChange={(e) => {
                        if (isFreeSubscription) {
                          toastMessage(
                            toastType.WARNING,
                            5000,
                            "Privacy status can be changed only on paid subscription."
                          );
                        } else {
                          let changeConfirmed = true;
                          if (!profileData?.public) {
                            changeConfirmed = window.confirm(
                              "Are you sure you want to make your whole profile (including books and ideacards) content public?"
                            );
                          }
                          if (changeConfirmed) {
                            privacyHandler(e);
                          }
                        }
                      }}
                      checked={profileData?.public}
                    />

                    <label className="switch__label" htmlFor="public">
                      <span
                        className={`switch_left ${
                          isFreeSubscription && "disabled"
                        }`}
                      >
                        <LockRoundedIcon />
                      </span>
                      <span
                        className={`switch_right ${
                          isFreeSubscription && "disabled"
                        }`}
                      >
                        <PublicIcon />
                      </span>
                    </label>
                  </div>
                </td>
                <td className="py-3">
                  <p
                    className={` text-xl desktop  ml-[20px] optionText ${
                      profileData?.public && "optionBorder"
                    }                       
                          ${isFreeSubscription && "disabled"}
                        `}
                  >
                    Public profile
                  </p>
                </td>
              </tr>
              <tr className="">
                <td className="py-3 text-right">
                  <p
                    className={`textPrimary text-xl desktop mr-[20px] ${
                      lightDarkMode === "light" && `optionBorder`
                    } `}
                  >
                    Light mode
                  </p>
                  <p className={`textPrimary text-xl  mobile mr-[20px] `}>
                    {lightDarkMode === "dark" ? (
                      <>Dark mode</>
                    ) : (
                      <>Light mode </>
                    )}
                  </p>
                </td>
                <td className="py-3">
                  <div className="switch">
                    <input
                      id="switch"
                      className="switch__input"
                      name="switch"
                      checked={lightDarkMode === "dark" ? true : false}
                      type="checkbox"
                      onChange={(e) => {
                        handleChangeLightMode(e);
                      }}
                    />
                    <label className="switch__label" htmlFor="switch">
                      <span className="switch_left">
                        <LightModeIcon />
                      </span>
                      <span className="switch_right">
                        <NightsStayIcon />
                      </span>
                    </label>
                  </div>
                </td>
                <td className="py-3">
                  {" "}
                  <p
                    className={`textPrimary text-xl ml-[32px] ${
                      lightDarkMode === "dark" && `optionBorder`
                    }`}
                  >
                    Dark mode
                  </p>
                </td>
              </tr>

              <tr className="">
                <td className="py-3 text-right">
                  <p className="text-xl mr-[20px] textPrimary">Log out now</p>
                </td>
                <td className="py-3">
                  <div className="switch">
                    <button
                      className={
                        "icon linkCollapsible flex items-center justify-center  !h-[30px] !w-[80px] !rounded-full !bg-[#E3E3E3]"
                      }
                      onClick={handleLogout}
                    >
                      <LogoutIcon
                        sx={{
                          fontSize: "1.5rem",
                        }}
                      />
                    </button>
                  </div>
                </td>
                <td className="py-3"></td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* bottom section */}
        {isDevelopment() && (
          <div className="mt-24  flex justify-around items-center bottom-section md:px-0 px-3 textPrimary">
            <div>
              <h3
                className={`border-b-2 border-[color:var(--fontColor)]  pb-3 mb-5 text-xl`}
              >
                {" "}
                My favourite books:
              </h3>

              <div
                className={`flex items-center justify-start h-[30px]  pr-[4px] rounded-2xl mt-[5px]`}
              >
                <div className={`${"circled-book-image flex gap-x-3"}`}>
                  <img
                    src="https://picsum.photos/200/300"
                    alt="book"
                    className="feedListsImg w-[18px]"
                  />
                  <p className="text-xl">How to Take smart...</p>
                </div>
              </div>
              <div
                className={`flex items-center justify-start h-[30px]  pr-[4px] rounded-2xl mt-[5px]`}
              >
                <div className={`${"circled-book-image flex gap-x-3"}`}>
                  <img
                    src="https://picsum.photos/200/300"
                    alt="book"
                    className="feedListsImg w-[18px]"
                  />
                  <p className="text-xl">How to Take smart...</p>
                </div>
              </div>
              <div
                className={`flex items-center justify-start h-[30px]  pr-[4px] rounded-2xl mt-[5px]`}
              >
                <div className={`${"circled-book-image flex gap-x-3"}`}>
                  <img
                    src="https://picsum.photos/200/300"
                    alt="book"
                    className="feedListsImg w-[18px]"
                  />
                  <p className="text-xl">How to Take smart...</p>
                </div>
              </div>
            </div>
            <div>
              <h3
                className={`border-b-2 border-[color:var(--fontColor)] pb-3 mb-5 text-xl`}
              >
                {" "}
                My top idea cards:
              </h3>

              <div
                className={`flex items-center justify-start h-[30px]  pr-[4px] rounded-2xl mt-[5px]`}
              >
                <div className={`${"circled-book-image flex gap-x-3"}`}>
                  <img
                    src="https://picsum.photos/200/300"
                    alt="book"
                    className="feedListsImg w-[18px]"
                  />
                  <p className="text-xl">How to Take smart...</p>
                </div>
              </div>
              <div
                className={`flex items-center justify-start h-[30px]  pr-[4px] rounded-2xl mt-[5px]`}
              >
                <div className={`${"circled-book-image flex gap-x-3"}`}>
                  <img
                    src="https://picsum.photos/200/300"
                    alt="book"
                    className="feedListsImg w-[18px]"
                  />
                  <p className="text-xl">How to Take smart...</p>
                </div>
              </div>
              <div
                className={`flex items-center justify-start h-[30px]  pr-[4px] rounded-2xl mt-[5px]`}
              >
                <div className={`${"circled-book-image flex gap-x-3"}`}>
                  <img
                    src="https://picsum.photos/200/300"
                    alt="book"
                    className="feedListsImg w-[18px]"
                  />
                  <p className="text-xl">How to Take smart...</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
