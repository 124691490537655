import { useEffect, useState, useRef } from "react";
import { Dialog, DialogActions, Button, Paper, styled } from "@mui/material";

import { store } from "../Utils/Store/Store";
import { selectIdeaCard } from "../Utils/Features/librarySlice";
import { updatePersistentDrawer } from "../Utils/Features/persistentDrawerSlice";
import {
  setCurrentTab,
  updateIdentifyIdeaCardData,
} from "../Utils/Features/IdentifyIdeaCardSlice";

import { getLabelId } from "./getIdeacardIcons";

const openCreateIdeaDialog = () => {
  setCreateDialogOpen(true);
};

export const closeCreateIdeaDialog = () => {
  setCreateDialogOpen(false);
};

const handleTextSelect = (event) => {
  event.preventDefault();
  closeCreateIdeaDialog();
  const selection = window.getSelection().toString().trim();
  if (selection !== "") {
    openCreateIdeaDialog();
  }
};

const createIdeaCard = (title) => {
  const createIdeaCardFromTextSelection = () => {
    const selection = window.getSelection();
    let itemSelf = selection.anchorNode;
    // selection anchor node is the span element if select all is used
    // otherwise it is the text inside the span and we need to get the parent element
    if (itemSelf.nodeName !== "SPAN") {
      itemSelf = itemSelf.parentElement;
    }
    const start = itemSelf.dataset.start;
    const book_id = itemSelf.dataset.book_id;
    const user_id = itemSelf.dataset.user_id;
    const highlight_id = itemSelf.id;
    const context = itemSelf.textContent;
    const ideacardObj = {
      book_id,
      label_id: getLabelId("KEYWORDS"),
      highlight_id,
      title,
      start,
      user_id,
      my_notes: [],
      picture_link: "",
      rating: 0,
      tags: [],
      level: 0,
      end: null,
      description: [{ highlight_id, start, context }],
    };
    if (highlight_id?.length && start?.length) {
      store.dispatch(updateIdentifyIdeaCardData(ideacardObj));
      // remember to clear selection.. :E
      window.getSelection().removeAllRanges();
      store.dispatch(updatePersistentDrawer("identify Ideacard"));
    }
    store.dispatch(setCurrentTab(0));
  };

  if (!title) {
    return false;
  }
  if (title.length >= 0) {
    store.dispatch(selectIdeaCard(null));
    store.dispatch(updateIdentifyIdeaCardData(null));
    createIdeaCardFromTextSelection();
  }
};

const handleEscGlobal = (event) => {
  if (event.keyCode === 27) {
    window.getSelection().removeAllRanges();
  }
};

function hasTouchSupport() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}

let setCreateDialogOpen = null;

export const addTextSelection = (selectableRef, setDialogOpen) => {
  const selectableElement = selectableRef.current;
  setCreateDialogOpen = setDialogOpen;
  if (selectableElement) {
    closeCreateIdeaDialog();
    const buttonStateHandler = () => {
      selectableElement.addEventListener("mouseup", handleTextSelect);
      selectableElement.addEventListener("keydown", handleEscGlobal);
      window.addEventListener("scroll", closeCreateIdeaDialog, true);
      if (hasTouchSupport()) {
        selectableElement.addEventListener("contextmenu", handleTextSelect);
      }
    };

    buttonStateHandler();
  }
};

export const cleanupTextSelection = (selectableRef) => {
  const selectableElement = selectableRef.current;

  if (selectableElement) {
    selectableElement.removeEventListener("mouseup", handleTextSelect);
    selectableElement.removeEventListener("keydown", handleEscGlobal);
    window.removeEventListener("scroll", closeCreateIdeaDialog, true);
    if (hasTouchSupport()) {
      selectableElement.removeEventListener("contextmenu", handleTextSelect);
    }
  }
};

const create = (handleClose) => {
  const selection = window.getSelection().toString().trim();
  createIdeaCard(selection);
  handleClose();
};

const copy = (handleClose) => {
  navigator.clipboard.writeText(window.getSelection().toString().trim());
  handleClose();
};

const selectall = (element) => {
  const selection = window.getSelection();
  let range = document.createRange();
  range.selectNodeContents(element);
  selection.removeAllRanges();
  selection.addRange(range);
};

const cancel = (handleClose) => {
  window.getSelection().removeAllRanges();
  handleClose();
};

export const useSelectedText = () => {
  const [text, setText] = useState("");
  const [element, setElement] = useState("");
  const select = () => {
    const selected = window.getSelection();
    setText(selected.toString());
    setElement(selected.anchorNode?.parentElement);
  };
  return [select, text, element];
};

export const CreateIdeaDialog = ({ open, handleClose }) => {
  const [select, , element] = useSelectedText();
  const [positionStyle, setPositionStyle] = useState({});
  const dialogRef = useRef();

  useEffect(() => {
    select();
  }, [select, open]);

  const selectedText = window.getSelection();

  useEffect(() => {
    if (element && dialogRef?.current && selectedText) {
      const highlightTop = element.getBoundingClientRect().top;
      const highlightBottom = element.getBoundingClientRect().bottom;
      const dialogHeight = dialogRef.current.offsetHeight;
      const dialogWidth = dialogRef.current.offsetWidth;
      const windowWidth = window.innerWidth;
      let range = selectedText.getRangeAt(0);
      let rect = range.getBoundingClientRect();
      let leftPosition;

      // Calculate left position dynamically
      if (rect.left + dialogWidth < windowWidth) {
        leftPosition = rect.left - 50;
      } else {
        leftPosition = windowWidth - dialogWidth - 30; // Adjust 30 as needed
      }

      var topPosition = 0;
      if (highlightTop < 1.5 * dialogHeight) {
        topPosition = highlightBottom + 30;
      } else {
        topPosition = highlightTop - dialogHeight - 10;
      }

      setPositionStyle({
        top: topPosition + "px",
        left: leftPosition + "px",
        height: "fit-content",
        width: "fit-content",
      });
    }
    return () => selectedText;
  }, [element, selectedText]);

  function PaperComponent(props) {
    return (
      <Paper
        {...props}
        sx={{
          margin: 0,
          maxHeight: "100%",
          border: "1px solid var(--fontColor)",
          background: "var(--cardBackgroundColor)",
          "& .MuiDialogActions-root": {
            gap: "10px",
          },
        }}
      />
    );
  }

  const ActionButton = styled(Button)(() => ({
    color: "var(--fontColor)",
    padding: 0,
    textTransform: "none",
    minWidth: "auto",
    whiteSpace: "nowrap",
  }));
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      disableEnforceFocus // Allows other things to take focus
      hideBackdrop // Hides the shaded backdrop
      PaperComponent={PaperComponent}
      sx={{
        ...positionStyle,
        "& .MuiPaper-elevation": {
          margin: 0,
        },
      }}
    >
      <div ref={dialogRef}>
        <DialogActions>
          <ActionButton
            onClick={() => {
              cancel(handleClose);
            }}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={() => {
              copy(handleClose);
            }}
          >
            Copy
          </ActionButton>
          <ActionButton
            onClick={() => {
              selectall(element);
            }}
          >
            Select all
          </ActionButton>
          <ActionButton
            className="font"
            onClick={() => {
              create(handleClose);
            }}
            autoFocus
          >
            Create Idea
          </ActionButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};
