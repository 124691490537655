import { useRef, useState } from "react";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ShareIcon from "@mui/icons-material/Share";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { DeepPopper } from "../../Common/DeepPopper/DeepPopper";
import { Share } from "../../../components/Common/Share";

const FeedBottom = ({ feeditem }) => {
  const [shareOpen, setShareOpen] = useState(false);
  const shareRef = useRef(null);

  return (
    <div className="pt-[20px]">
      <div className="flex justify-between ">
        <div>
          <span className="pr-[20px]">
            <FavoriteBorderIcon className="socialButtonStyle under-construction" />
          </span>
          <span className="pr-[20px]">
            <ChatBubbleOutlineIcon className="socialButtonStyle under-construction" />
          </span>
        </div>
        <div>
          <span className="pr-[20px]">
            <TurnedInNotIcon className="socialButtonStyle under-construction" />
          </span>
          <span>
            <MoreVertIcon className="socialButtonStyle under-construction" />
          </span>
          <span>
            <ShareIcon
              className="socialButtonStyle"
              onClick={() => {
                setShareOpen(true);
              }}
              ref={shareRef}
            />
            <DeepPopper
              anchorEl={shareRef?.current}
              open={shareOpen}
              closeHandler={() => {
                setShareOpen(false);
              }}
            >
              <Share
                closeHandler={() => {
                  setShareOpen(false);
                }}
                text={`"${feeditem?.title}" \n\n -- ${feeditem?.book[0]?.title} (${feeditem?.book[0]?.author})`}
                pictureLink={feeditem.picture_link}
              />
            </DeepPopper>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FeedBottom;
