import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublicIcon from "@mui/icons-material/Public";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CloseIcon from "@mui/icons-material/Close";
import { Menu, MenuItem, Popover, Typography } from "@mui/material";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Ownership } from "../Common/Ownership/Ownership";
import { Loading } from "../Loading";

import { fetchExport } from "../../Utils/Features/contentSlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus";

import "../../pages/MyLibrary/MyLibrary.css";
import "./Markdown.css";

import {
  updateBook,
  deleteBook,
  restoreDeletedBook,
  updateBookPrivacy,
} from "../../Utils/Features/librarySlice";
import { checkIsFreeSubscription } from "../../Utils/Features/authSlice";
import { AmazonSingleSync } from "./AmazonSingleSync";
import { SyncOrigin } from "../Connectors/ChromeExtensionCommunication";

import {
  QontoConnector,
  QontoStepIcon,
  stepLabelStyling,
  TimeLineSpan,
} from "./styled";
import toastMessage, { toastType } from "../../helperFunctions/toastMessage";

const steps = ["Read", "Highlights", "Idea Cards"];
const currentURL = "library";

export const BookIntro = ({ bookData, list }) => {
  const dispatch = useDispatch();
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isFreeSubscription = useSelector((state) =>
    checkIsFreeSubscription(state)
  );

  const moreRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const privacyRef = useRef(null);
  const [privacyMenuOpen, setPrivacyMenuOpen] = useState(false);

  const checkURL = window.location.toString().includes(currentURL);

  const togglePrivacyMenu = () => {
    if (isFreeSubscription) {
      toastMessage(
        toastType.WARNING,
        5000,
        "Privacy of books can be changed only on paid subscription."
      );
    }
    setPrivacyMenuOpen(!privacyMenuOpen);
  };

  return (
    <div className="libraryLists">
      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
        <div>
          <div style={{ position: "relative" }}>
            <img
              src={bookData?.img_path}
              alt={bookData?.title}
              className="libraryListsImg"
            />
            {(bookData?.source_type === "EBOOK_SAMPLE" || bookData?.demo) && (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  color: "var(--dark)",
                  background: "#F1F1F1",
                  width: "100%",
                  transition: ".5s ease",
                  opacity: 0.7,
                  fontSize: "20px",
                  padding: "px",
                  textAlign: "center",
                }}
              >
                {bookData?.source_type === "EBOOK_SAMPLE" && (
                  <span>SAMPLE</span>
                )}
                {bookData?.demo && <span>DEMO</span>}
              </div>
            )}
          </div>
          <div className="rating">
            {list || bookData?.read_only ? (
              <Rating
                name="read-only"
                sx={{ color: "#FF6600" }}
                size={isSmDown ? "small" : "medium"}
                value={bookData?.rating}
                readOnly
              />
            ) : (
              <div>
                <Rating
                  name="bookRating"
                  sx={{ color: "#FF6600" }}
                  size={isSmDown ? "small" : "medium"}
                  value={bookData?.rating}
                  onChange={(event, newRating) => {
                    dispatch(
                      updateBook({
                        bookId: bookData?._id,
                        newData: {
                          rating: newRating,
                        },
                      })
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <Stack
          direction="column"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          {/* //CardHeaderTitle */}
          <div>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              mb={1}
            >
              {bookData?.deleted_at ? (
                <div className="alert">
                  <ReportGmailerrorredIcon className="alertIcon" />
                  This book, including notes, highlights and ideas has been
                  scheduled to be deleted from your DeepRead library.
                </div>
              ) : (
                <div>
                  <Ownership
                    demo={bookData?.demo}
                    ownership={bookData?.ownership}
                  />
                </div>
              )}
              {!bookData?.read_only && (
                <div
                  className="bookmarkButtons"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    {!bookData?.demo && (
                      <>
                        {bookData?.public ? (
                          <PublicIcon
                            className="socialButtonStyle orange"
                            ref={privacyRef}
                            onClick={() => {
                              togglePrivacyMenu();
                            }}
                          ></PublicIcon>
                        ) : (
                          <LockRoundedIcon
                            className="socialButtonStyle"
                            ref={privacyRef}
                            onClick={() => {
                              togglePrivacyMenu();
                            }}
                          ></LockRoundedIcon>
                        )}
                      </>
                    )}
                    <MoreVertIcon
                      className="socialButtonStyle"
                      ref={moreRef}
                      onClick={() => {
                        setMenuOpen(!menuOpen);
                      }}
                    />
                    <BookMenu
                      book={bookData}
                      anchorEl={menuOpen ? moreRef?.current : null}
                      closeMenu={() => setMenuOpen(false)}
                    />
                    <PrivacyMenu
                      book={bookData}
                      anchorEl={privacyMenuOpen ? privacyRef?.current : null}
                      closeMenu={() => {
                        setPrivacyMenuOpen(false);
                      }}
                    />
                  </Stack>
                </div>
              )}
            </Stack>
            <h3 className="multi-line-ellipsis">{bookData?.title}</h3>
            <span className="">
              By{" "}
              {bookData?.author
                .replace(/;/g, " & ")
                .split(" & ")
                .map((name) => name.split(", ").reverse().join(" "))
                .join(" & ")}
            </span>
          </div>
          {!isSmDown && <TimeLine bookData={bookData} />}
        </Stack>
      </Stack>
      {isSmDown && <TimeLine bookData={bookData} />}
      {bookData?.demo === false && !isSmDown && checkURL ? (
        <div className="fetchStatusIconContainer">
          <AmazonSingleSync bookData={bookData} originView={SyncOrigin.Book} />
        </div>
      ) : null}
    </div>
  );
};

const PrivacyMenu = ({ book, anchorEl, closeMenu }) => {
  const dispatch = useDispatch();
  const isFreeSubscription = useSelector((state) =>
    checkIsFreeSubscription(state)
  );

  const setPublic = (isPublic) => {
    dispatch(
      updateBookPrivacy({
        bookId: book?._id,
        isPublic,
      })
    );
    closeMenu();
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={anchorEl ? true : false}
      onClose={closeMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {isFreeSubscription ? (
        <Typography className="px-4">
          Privacy change is only available on paid subscriptions
        </Typography>
      ) : book?.public ? (
        <MenuItem key="setPrivate" onClick={() => setPublic(false)}>
          Set Book Private
        </MenuItem>
      ) : (
        <MenuItem key="setPublic" onClick={() => setPublic(true)}>
          Set Book Public
        </MenuItem>
      )}
    </Menu>
  );
};

const BookMenu = ({ book, anchorEl, closeMenu }) => {
  const dispatch = useDispatch();
  const { export: markdown, exportStatus } = useSelector(
    (state) => state.content
  );
  const exportRef = useRef();
  const [markdownOpen, setMarkdownOpen] = useState(false);
  const [exportType, setExportType] = useState(false);

  const removeBook = () => {
    if (book?._id) {
      dispatch(
        deleteBook({
          bookId: book?._id,
        })
      );
    }
    closeMenu();
  };
  const restoreBook = () => {
    if (book?._id) {
      dispatch(
        restoreDeletedBook({
          bookId: book?._id,
        })
      );
    }
    closeMenu();
  };

  const exportHighlights = () => {
    const type = "highlights";
    setExportType(type);
    dispatch(
      fetchExport({
        bookId: book?._id,
        exportType: type,
      })
    );
    setMarkdownOpen(true);
  };

  const exportWithIdeaCards = () => {
    const type = "ideacards";
    setExportType(type);
    dispatch(
      fetchExport({
        bookId: book?._id,
        exportType: type,
      })
    );
    setMarkdownOpen(true);
  };

  const downloadMarkdown = () => {
    const element = document.createElement("a");
    const file = new Blob([markdown], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = book.title + " - " + exportType + ".md";
    element.click();
    closeExport();
  };

  const closeExport = () => {
    setMarkdownOpen(false);
    closeMenu();
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl ? true : false}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {book?.deleted_at ? (
          <MenuItem key="restore" onClick={restoreBook}>
            Restore Book
          </MenuItem>
        ) : (
          <MenuItem key="remove" onClick={removeBook}>
            Remove Book
          </MenuItem>
        )}
        <MenuItem
          key="exportHighlights"
          onClick={exportHighlights}
          ref={exportRef}
        >
          Export Highlights
        </MenuItem>
        <MenuItem
          key="exportWithIdeaCards"
          onClick={exportWithIdeaCards}
          ref={exportRef}
        >
          Export with Ideacards
        </MenuItem>
      </Menu>
      <Popover
        open={markdownOpen}
        anchorEl={exportRef?.current}
        onClose={closeExport}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: { width: "100%" },
          },
        }}
      >
        <div>
          <button className="markdown-button" onClick={closeExport}>
            <CloseIcon style={{ fontSize: "18px", paddingBottom: "3px" }} />
          </button>
          {exportStatus === ApiStatus.Fulfilled && (
            <button className="markdown-button" onClick={downloadMarkdown}>
              Download Markdown File
            </button>
          )}
        </div>
        {exportStatus === ApiStatus.Fulfilled ? (
          <Markdown remarkPlugins={[remarkGfm]} className="markdown">
            {markdown}
          </Markdown>
        ) : exportStatus === ApiStatus.Pending ? (
          <Loading />
        ) : (
          <div className="p-10">
            Oopsie daisy! 🌼 <br />
            It looks like our export gnomes are on a coffee break ☕. <br />
            We couldn't create the export right now, but don't worry—they'll be
            back soon! <br />
            Try again in a bit! 🚀
          </div>
        )}
      </Popover>
    </>
  );
};

const TimeLine = ({ bookData }) => {
  const [activeStep, setActiveStep] = useState(-1);

  useEffect(() => {
    if (bookData?.progress > 90) {
      setActiveStep(0.5);
    }
    if (bookData?.h_progress >= 1) {
      setActiveStep(1.5);
    }
    if (bookData?.i_progress > 0) {
      setActiveStep(3);
    }
  }, [bookData]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
      }}
    >
      {bookData?.source_type !== "EBOOK_SAMPLE" && (
        <div style={{ flex: 1 }}>
          <Box sx={{ width: "100%", marginTop: "32px" }}>
            <Stepper
              alternativeLabel
              activeStep={Math.round(activeStep)}
              connector={<QontoConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    sx={{
                      ...stepLabelStyling,
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                textAlign: "center",
                // marginTop: "16px",
              }}
            >
              <>
                {bookData?.progress !== null ? (
                  <TimeLineSpan>
                    {bookData?.progress
                      ? `${Math.ceil(bookData?.progress)}%`
                      : "0%"}
                  </TimeLineSpan>
                ) : (
                  <TimeLineSpan>...</TimeLineSpan>
                )}
                {bookData?.h_progress !== null ? (
                  <TimeLineSpan>{bookData?.h_progress}</TimeLineSpan>
                ) : (
                  <TimeLineSpan>...</TimeLineSpan>
                )}
                {bookData?.i_progress !== null ? (
                  <TimeLineSpan>
                    {bookData?.i_progress ? bookData?.i_progress : "0"}
                  </TimeLineSpan>
                ) : (
                  <TimeLineSpan>...</TimeLineSpan>
                )}
              </>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};
