import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import TagIcon from "@mui/icons-material/Tag";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import PublicIcon from "@mui/icons-material/Public";

import { fetchBookList } from "../Utils/Features/librarySlice";
import { ApiStatus } from "../Utils/Features/ApiStatus";
import { fetchFeedTags } from "../Utils/Features/feedSlice";
import { setFeedFilter } from "../Utils/Features/userConfigSlice";
import { ignoreOrderCompare } from "../helperFunctions/equalityChecker";
import { lowercaseAllButFirstLetter } from "../helperFunctions/strings";

import Filter from "../components/Common/Filter";
import Search from "../components/Common/Search";
import Sort from "../components/Common/Sort";

import { executeWithIncrementalDelay } from "../helperFunctions/timing";
import { getNumericEnvVariable } from "../helperFunctions/envVars";

export const FilterEnum = {
  HIGHLIGHTS: "Highlights",
  IDEAS: "Ideas",
  // TODO: Think if should be separate?
  OWN: "My Content",
  PUBLIC: "Public",
};

export const filterItemElements = [
  {
    id: FilterEnum.HIGHLIGHTS,
    title: FilterEnum.HIGHLIGHTS,
    isChecked: false,
    icon: "SquareIcon",
  },
  {
    id: FilterEnum.IDEAS,
    title: FilterEnum.IDEAS,
    isChecked: false,
    icon: "TipsAndUpdatesIcon",
    parentId: FilterEnum.IDEAS, // main category
  },
];

export const sortingItemList = [
  { id: 0, sort: "updated", title: "Last edited", isChecked: false },
  { id: 1, sort: "created", title: "Last created", isChecked: false },
  { id: 2, sort: "statistic", title: "Mingle mix", isChecked: false },
  { id: 3, sort: "sample", title: "Random picks", isChecked: true },
];

export const filterContentTypes = [
  {
    id: FilterEnum.OWN,
    title: FilterEnum.OWN,
    isChecked: false,
    icon: "DiamondIcon",
  },
  {
    id: FilterEnum.PUBLIC,
    title: FilterEnum.PUBLIC,
    isChecked: false,
    icon: "PublicIcon",
  },
];

const FeedMenu = () => {
  const dispatch = useDispatch();
  const { bookList, bookListStatus } = useSelector((state) => state.library);
  const { feedFilter: filter } = useSelector((state) => state.userConfig);

  const sortDefaultItem =
    sortingItemList.find((type) => type.sort === filter?.sortFilter)?.id ?? 3;
  const filterBooksRef = useRef();
  const [showBooks, setShowBooks] = useState(true);
  const [filterItemBooks, setFilterItemBooks] = useState([]);

  const filterElementsRef = useRef();
  const [showElements, setShowElements] = useState(true);
  const [searchQuery, setSearchQuery] = useState(filter?.filter?.search);

  const filterTagsRef = useRef();
  const [filterTags, setFilterTags] = useState([]);
  const { tags, tagsStatus } = useSelector((state) => state.feed);
  const [showTags, setShowTags] = useState(true);

  const sortingRef = useRef();
  const [hideDropDown, sethideDropDown] = useState(true);
  const [showItem, setShowItem] = useState(true);

  const filterContentRef = useRef();
  const [showContentTypes, setShowContentTypes] = useState(true);

  const [delayFilter, setDelayFilter] = useState(
    filter?.filter ?? { search: "" }
  );
  const [delaySortFilter, setDelaySortFilter] = useState(null);
  const [delayFilterElements, setDelayFilterElements] = useState(null);
  const [delayFilterBooks, setDelayFilterBooks] = useState(null);
  const [delayFilterTags, setDelayFilterTags] = useState(null);
  const [filterTimer, setFilterTimer] = useState(null);
  const [delayFilterContentTypes, setDelayFilterContentTypes] = useState(null);

  const [disabledFilters, setDisabledFilters] = useState([]);

  if (
    localStorage.getItem("ideacardIcons") &&
    !filterItemElements.find((item) => item.icon.startsWith("IDEAICON_"))
  ) {
    filterItemElements.forEach((element) => {
      // if no ideas or highlights checked in saved filter set default both on:
      if (
        !filter?.filter?.elements?.includes(FilterEnum.IDEAS) &&
        !filter?.filter?.elements?.includes(FilterEnum.HIGHLIGHTS)
      ) {
        if (
          element._id === FilterEnum.IDEAS ||
          element._id === FilterEnum.HIGHLIGHTS
        ) {
          element.isChecked = true;
        }
        // else use saved filter values
      } else {
        element.isChecked = filter?.filter?.elements?.includes(element.id);
      }
    });
    filterItemElements.push(
      ...JSON.parse(localStorage.getItem("ideacardIcons")).map((icon) => {
        return {
          id: icon._id,
          title: lowercaseAllButFirstLetter(icon.label),
          isChecked: filter?.filter?.elements?.includes(icon._id),
          icon: "IDEAICON_" + icon._id,
          childOf: FilterEnum.IDEAS, // subcategory of ideas category
        };
      })
    );
  }

  const HandleOutsideClick = (event, isForcefullyPrevented = false) => {
    if (
      filterBooksRef.current &&
      filterBooksRef.current.contains(event.target)
    ) {
      if (!isForcefullyPrevented) {
        setShowBooks(true);
      }
    }

    if (
      filterElementsRef.current &&
      filterElementsRef.current.contains(event.target)
    ) {
      if (!isForcefullyPrevented) {
        setShowElements(true);
      }
    }

    if (filterTagsRef.current && filterTagsRef.current.contains(event.target)) {
      if (!isForcefullyPrevented) {
        setShowTags(true);
      }
    }

    if (sortingRef.current && sortingRef.current.contains(event.target)) {
      if (!isForcefullyPrevented) {
        setShowItem(true);
      }
    }
  };

  useEffect(() => {
    if (!showItem || !setShowTags || !setShowElements || !setShowBooks) {
      document.addEventListener("click", HandleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", HandleOutsideClick);
    };
  }, [showItem, setShowTags, setShowElements, setShowBooks]);

  useEffect(() => {
    if (bookList?.length === 0 && bookListStatus !== ApiStatus.Fulfilled) {
      dispatch(fetchBookList());
    }
  }, [dispatch, bookList, bookListStatus]);

  useEffect(() => {
    if (tagsStatus === ApiStatus.NotRun) {
      dispatch(fetchFeedTags());
    }
  }, [dispatch, tags, tagsStatus]);

  useEffect(() => {
    if (tags && tags.length >= 0) {
      const allTags = [...new Set(tags)];

      // Sort the tags in ascending order
      allTags.sort();

      const temp = allTags.map((tag, index) => ({
        id: index,
        title: tag,
        isChecked: filter?.filter?.tags?.includes(tag),
      }));
      setFilterTags(temp);
    }
    // Tags list for the filter should be set only at first render but not later when tags are checked or unchecked
    // so filter?.filter?.tags change is ignored
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    const filterBooks = [];
    bookList?.forEach((book) => {
      filterBooks.push({
        id: book._id,
        title: book.title.slice(0, 30),
        img: book.img_path,
        isChecked: filter?.filter?.books?.includes(book._id),
      });
    });
    setFilterItemBooks(filterBooks);
    // Books list for the filter should be set only at first render but not later when books are checked or unchecked
    // so filter?.filter?.books change is ignored
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookList]);

  useEffect(() => {
    filterContentTypes?.forEach((type) => {
      type.isChecked = filter?.filter?.origin?.includes(type.id);
    });
    // update saved settings only on initial render
    // so filter?.filter?.origin change is ignored
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (event) => {
    const query = event?.target?.value?.toLowerCase() ?? "";
    setSearchQuery(query);
  };

  useEffect(() => {
    if (searchQuery !== null) {
      setDelayFilter((delayFilter) => ({
        ...delayFilter,
        search: searchQuery,
      }));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (delayFilterBooks !== null) {
      // don't send books to backend if books filter is disabled
      const sendBooks = disabledFilters?.includes("books")
        ? []
        : delayFilterBooks;
      setDelayFilter((delayFilter) => ({
        ...delayFilter,
        books: sendBooks,
      }));
    }
  }, [delayFilterBooks, disabledFilters]);

  useEffect(() => {
    if (delayFilterElements !== null) {
      // don't send elements to backend if elements filter is disabled
      const sendElements = disabledFilters?.includes("elements")
        ? []
        : delayFilterElements;
      setDelayFilter((delayFilter) => ({
        ...delayFilter,
        elements: sendElements,
      }));
    }
  }, [delayFilterElements, disabledFilters]);

  useEffect(() => {
    if (delayFilterTags !== null) {
      // don't send tags to backend if tags filter is disabled
      const sendTags = disabledFilters?.includes("tags") ? [] : delayFilterTags;
      setDelayFilter((delayFilter) => ({
        ...delayFilter,
        tags: sendTags,
      }));
    }
  }, [delayFilterTags, disabledFilters]);

  const disableFilters = useCallback(() => {
    let disableList = ["tags", "books"];
    if (delaySortFilter?.includes("statistic")) {
      disableList.push("elements");
    }

    setDisabledFilters((oldList) =>
      oldList.filter((item) => ![...disableList, "elements"].includes(item))
    );
    if (
      delaySortFilter?.includes("statistic") ||
      delaySortFilter?.includes("sample") ||
      (delayFilterContentTypes?.includes("Public"))
    ) {
      setDisabledFilters((oldList) => [
        ...oldList,
        ...disableList.filter((item) => !oldList.includes(item)),
      ]);
    }
  }, [delayFilterContentTypes, delaySortFilter]);

  useEffect(() => {
    disableFilters();
  }, [delaySortFilter, disableFilters]);

  useEffect(() => {
    disableFilters();
    if (delayFilterContentTypes !== null) {
      setDelayFilter((delayFilter) => ({
        ...delayFilter,
        origin: delayFilterContentTypes,
      }));
    }
  }, [delayFilterContentTypes, disableFilters]);

  useEffect(() => {
    const updatedFilter = {
      filter: delayFilter,
      sortFilter: delaySortFilter ?? filter?.sortFilter,
    };

    const delayTimeInMS = getNumericEnvVariable(
      "REACT_APP_FEED_FILTER_DELAY_INMS",
      800
    );
    if (!ignoreOrderCompare(filter, updatedFilter)) {
      executeWithIncrementalDelay(
        filterTimer,
        setFilterTimer,
        () => {
          dispatch(setFeedFilter(updatedFilter));
        },
        delayTimeInMS
      );
    }
    // adding filterTimer to the dependency array creates infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayFilter, delaySortFilter, dispatch]);

  return (
    <>
      <div className="mx-auto pb-[15px]">
        <Search
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={"search"}
          handleSearchChange={handleSearchChange}
        />
      </div>
      <div ref={sortingRef}>
        <Sort
          SortIcon={SortOutlinedIcon}
          sortingItemList={sortingItemList}
          hideDropDown={hideDropDown}
          sethideDropDown={sethideDropDown}
          setShowItem={setShowItem}
          showItem={showItem}
          sortingRef={sortingRef}
          defaultItem={sortDefaultItem}
          page={"feed"}
          setSortFilter={setDelaySortFilter}
        />
      </div>
      <div className="pt-[15px]">
        <div ref={filterContentRef}>
          <Filter
            filterItemList={filterContentTypes}
            FilterIcons={PublicIcon}
            title={"Filter:"}
            filterByTitle={"Content Source"}
            enableSearch={false}
            enableSelectAll={false}
            showFilter={showContentTypes}
            setShowFilter={setShowContentTypes}
            handleOutsideClick={HandleOutsideClick}
            filterType={"content"}
            page={"feed"}
            filterChange={setDelayFilterContentTypes}
            disabledFilters={disabledFilters}
          />
        </div>
        <div ref={filterElementsRef}>
          <Filter
            filterItemList={filterItemElements}
            FilterIcons={ViewAgendaOutlinedIcon}
            filterByTitle={"Content Types"}
            enableSearch={false}
            enableSelectAll={false}
            showFilter={showElements}
            setShowFilter={setShowElements}
            handleOutsideClick={HandleOutsideClick}
            filterType={"elements"}
            page={"feed"}
            filterChange={setDelayFilterElements}
            disabledFilters={disabledFilters}
          />
        </div>
        <div ref={filterTagsRef}>
          <Filter
            filterItemList={filterTags}
            filterLoadStatus={tagsStatus}
            FilterIcons={TagIcon}
            filterByTitle={"Tags"}
            enableSearch={true}
            enableSelectAll={true}
            showFilter={showTags}
            setShowFilter={setShowTags}
            handleOutsideClick={HandleOutsideClick}
            filterType={"tags"}
            page={"feed"}
            filterChange={setDelayFilterTags}
            disabledFilters={disabledFilters}
          />
        </div>
        <div ref={filterBooksRef}>
          <Filter
            filterItemList={filterItemBooks}
            filterLoadStatus={bookListStatus}
            FilterIcons={AutoStoriesIcon}
            filterByTitle={"Books"}
            enableSearch={true}
            enableSelectAll={true}
            showFilter={showBooks}
            setShowFilter={setShowBooks}
            handleOutsideClick={HandleOutsideClick}
            filterType={"books"}
            page={"feed"}
            filterChange={setDelayFilterBooks}
            disabledFilters={disabledFilters}
          />
        </div>
      </div>
    </>
  );
};

export default FeedMenu;
