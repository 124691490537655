import { useState, useRef, useLayoutEffect } from "react";
import {useSelector} from "react-redux";
import { Box, Collapse } from "@mui/material";
import  "./ExpandBox.css"

const defHeight = 100;

export const ExpandBox = ({collapsedHeight, alwaysExpanded, children}) => {
    const lightDarkMode = useSelector(
        (state) => state?.lightDarkModeSlice?.currentMode
      );
    const [expanded, setExpanded] = useState(false);
    const [collapsedSize, setCollapsedSize] = useState(collapsedHeight ?? defHeight);
    const [blurred, setBlurred] = useState(false);
    const ref = useRef();

    useLayoutEffect(() => {
        if(alwaysExpanded) {
            setBlurred(false);
            setCollapsedSize(ref.current.firstChild.scrollHeight);
        } else {
            const minHeight = collapsedHeight ?? defHeight;
            setCollapsedSize(minHeight);
            if(ref.current.firstChild.scrollHeight < minHeight) {
                setCollapsedSize(ref.current.firstChild.scrollHeight);
            } 
            if(ref.current.clientHeight === ref.current.scrollHeight) {
                if(ref.current.firstChild.scrollHeight > minHeight) {
                    setBlurred(true);
                } else {
                    setBlurred(false);
                }
            } else if (ref.current.clientHeight < ref.current.scrollHeight) {
                if(expanded) {
                    setBlurred(false);
                } else {
                    setBlurred(true);
                }
            } 
        }
    }, [ref, expanded, alwaysExpanded, collapsedHeight]);

    return (
        <Box
            onClick={() => {setExpanded(!expanded)}}       
        >
            <Collapse 
                ref={ref} 
                in={ alwaysExpanded || expanded } 
                collapsedSize={collapsedSize}
                className={`${ blurred && `blurred ${`blurred-background-${lightDarkMode}`}` }`}
            >
                {children}
            </Collapse >
        </Box>  
    );

}