import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Virtuoso } from "react-virtuoso";

import Stack from "@mui/material/Stack";
import SyncIcon from "@mui/icons-material/Sync";

import kindleLogo from "../../Assets/kindle-logo.png";

import ChromeExtensionConnector from "../../components/Connectors/ChromeExtensionCommunication";
import { installExtensionToast } from "../../components/Connectors/ExtensionInstallationPrompt";

import "./MyLibrary.css";

import { BookIntro } from "../../components/Book/Intro";
import { Loading } from "../../components/Loading";
import FilterSummary from "../../components/Common/FilterSummary";

import {
  selectBook,
  scrollStateOff,
  fetchLibrary,
  incrementPage,
  getCurrentBook,
} from "../../Utils/Features/librarySlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus";
import { ExpandBox } from "../../components/Common/ExpandBox";

export const itemIdCreator = (title) => {
  const splitedTitle = title?.split(" ");
  const requiredTitle = splitedTitle?.join("-");
  const requiredTitleSplit = requiredTitle?.split("#");
  const titleWithoutHash = requiredTitleSplit?.join("");
  return titleWithoutHash;
};

const BookViewCard = ({ item, index, highlight }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentBook = useSelector((state) => state.library.currentBook);

  const bookCardClickHandler = (index, item) => {
    if (item?._id !== currentBook?._id) {
      dispatch(selectBook(item._id));
    }

    navigate("/views");
    // TODO call the sync for highlights event towards our extension
  };

  return (
    <div
      key={item?.id}
      id={item?.title}
      className={`libraryListsContainer ${highlight && "highlightBook"}`}
      onClick={() => bookCardClickHandler(index, item)}
      style={{ cursor: "pointer", marginBottom: "10px" }}
    >
      <Stack direction={"column"} sx={{ width: "100%" }}>
        <BookIntro bookData={item} list="true" />
      </Stack>
    </div>
  );
};

function MyLibrary() {
  const dispatch = useDispatch();
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const extensionInstalled =
    localStorage.getItem("extensionInstalled") === "true";
  const { userId } = useSelector((state) => state.auth);
  const { amazonSyncDisabled, bookSyncMessage } = useSelector(
    (state) => state.amazonSync
  );

  const library = useSelector((state) => state.library);
  const {
    libraryData,
    libraryStatus,
    libraryError,
    paginationInfo,
    scrollToCurrentBookStateOn,
  } = library;
  const currentBook = useSelector((state) => getCurrentBook(state));

  const { libraryFilter: filter } = useSelector((state) => state.userConfig);
  const [hasMoreBooks, setHasMoreBooks] = useState(false);
  const virtuoso = useRef(null);

  useEffect(() => {
    dispatch(fetchLibrary());
  }, [dispatch, filter]);

  useEffect(() => {
    if (paginationInfo?.currentPage < paginationInfo?.totalPages) {
      setHasMoreBooks(true);
    } else {
      setHasMoreBooks(false);
    }
  }, [paginationInfo]);

  const loadMore = () => {
    if (hasMoreBooks) {
      dispatch(incrementPage());
      dispatch(fetchLibrary());
    }
  };

  useEffect(() => {
    if (scrollToCurrentBookStateOn && currentBook) {
      const currentBookIndex = libraryData?.findIndex(
        (data) => data._id === currentBook._id
      );
      virtuoso?.current?.scrollToIndex({
        index: currentBookIndex,
      });
      dispatch(scrollStateOff());
    }
  }, [scrollToCurrentBookStateOn, currentBook, dispatch, libraryData]);

  const handleAmazonSync = async () => {
    // notify the user, that this is only doable using the extension :D
    if (!extensionInstalled) {
      installExtensionToast();
      return;
    }

    try {
      ChromeExtensionConnector.SyncAmazonBooks(userId);
    } catch (error) {
      console.log("Error handleAmazonSync: ", error);
    }
  };

  return (
    <div className="feedParentContainer">
      <div className="feedBoxLayout flexbox">
        <div className="content">
          {libraryStatus === ApiStatus.Rejected ? (
            <div title={libraryError}>Loading Library failed.</div>
          ) : libraryStatus === ApiStatus.Pending &&
            // show loading circle only when no data.. otherwise virtuoso list jumps to first item always..
            // also kinda pointless with virtuoso loading anyway
            libraryData?.length === 0 ? (
            <Loading />
          ) : (
            <Virtuoso
              ref={virtuoso}
              totalCount={libraryData?.length}
              endReached={loadMore}
              overscan={{ main: 800, reverse: 800 }}
              itemContent={(index) => {
                const item = libraryData[index];
                // get orignal index of this item
                // NOTES: we didn't passed _id as that needs updation at many places so we are finding indexing from the oringal index and passing that index
                // as in book detail view we are fetching data based on that passed index
                let originalIndex = libraryData?.findIndex(
                  (data) => data._id === item._id
                );
                if (index < 0) {
                  originalIndex = index;
                }
                return (
                  <div id={"scroll" + item._id} key={item._id}>
                    <BookViewCard
                      item={item}
                      index={originalIndex}
                      highlight={item._id === currentBook?._id}
                    />
                  </div>
                );
                // last item rendered is the sync bar:
              }}
              components={{
                Header: () => {
                  const filterExists = !Object.values(filter.filter).every(
                    (value) => value.length === 0
                  );
                  return libraryData?.length === 0 ? (
                    <div className="libraryListsContainer flex justify-center">
                      <FilterSummary
                        title="
                        No books found in the library, with the
                        selected filter:"
                        filter={filter}
                      />
                    </div>
                  ) : (
                    filterExists && (
                      <div className="libraryListsContainer flex justify-center">
                        <ExpandBox collapsedHeight={80}>
                          <FilterSummary
                            title="Filter settings used for the library:"
                            filter={filter}
                          />
                        </ExpandBox>
                      </div>
                    )
                  );
                },
                Footer: () => {
                  const showSync = !amazonSyncDisabled && !isSmDown;
                  const showEndOfContent = !hasMoreBooks;
                  const showMobileSyncMessage = !amazonSyncDisabled && isSmDown;
                  if (showSync || showEndOfContent) {
                    return (
                      <div className="">
                        {showEndOfContent && (
                          <>
                            {libraryData?.length !== 0 && (
                              <div className="libraryListsContainer flex justify-center">
                                <FilterSummary
                                  title="No more books, with the selected filter:"
                                  filter={filter}
                                />
                              </div>
                            )}
                          </>
                        )}
                        {showSync && (
                          <div
                            className={`footer bg-[#FFE0CC] border rounded-[12px] border-[#e5e4e4] flex items-center px-[1.4rem] py-[0.7rem] my-[20px] bg-[var(--backgroundSync)]`}
                          >
                            <img
                              src={kindleLogo}
                              className={`max-w-[230px] ml-[60px] rounded-[38px]`}
                              alt="kindle"
                            />
                            <h3 className="max-w-[230px] ml-[60px]">
                              Get your Amazon Kindle books and highlights into
                              DeepRead
                            </h3>
                            <button
                              className="bg-[#FF6600] text-white whitespace-nowrap px-[15px] py-[6px] rounded-[33px] font-semibold ml-auto"
                              onClick={handleAmazonSync}
                              title={bookSyncMessage}
                            >
                              <span className="rotate-sync pr-3">
                                <SyncIcon />
                              </span>{" "}
                              Sync now
                            </button>
                          </div>
                        )}
                        {showMobileSyncMessage && (
                          <div
                            className={`footer bg-[#FFE0CC] border rounded-[12px] border-[#e5e4e4] flex items-center px-[1rem] py-[0.5rem] my-[20px] bg-[var(--backgroundSync)]`}
                            style={{ flexDirection: isSmDown ? 'column' : 'row', textAlign: isSmDown ? 'center' : 'left' }}
                          >
                            <img
                              src={kindleLogo}
                              className={`max-w-[100px] sm:max-w-[150px] rounded-[20px] sm:rounded-[38px] mb-2 sm:mb-0`}
                              alt="kindle"
                              style={{ marginRight: isSmDown ? '0' : '20px', marginBottom: isSmDown ? '10px' : '0' }}
                            />
                            <h3 className="max-w-[230px] sm:max-w-[300px]" style={{ fontSize: isSmDown ? '14px' : '16px' }}>
                              To sync your Kindle books and highlights, please use a PC browser, install our extension, and then enjoy the mobile version.
                            </h3>
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MyLibrary;
