import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { fetchTags } from "./librarySlice";
import { getHeaders } from "./authSlice";
import { ApiStatus } from "./ApiStatus";
import { setFeedFilter } from "./userConfigSlice";
import { getApiUrl } from "../../helperFunctions/envVars";

const apiUrl = getApiUrl();
const apiRoot = apiUrl + "/api";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  feedList: [],
  page: 1,
  tags: [],
  tagsStatus: ApiStatus.NotRun,
  paginationInfo: null,
};

export const feed = createAsyncThunk("feed/list", async (filter, thunkAPI) => {
  const page = thunkAPI.getState().feed.page;
  const response = await axios.post(
    `${apiRoot}/feed/list?page=${page}`,
    filter,
    getHeaders()
  );
  return response.data;
});

export const fetchFeedTags = createAsyncThunk(
  "feed/fetchFeedTags",
  async (type, thunkAPI) => {
    return fetchTags("idea,highlight", thunkAPI);
  }
);

export const feedSlice = createSlice({
  name: "feedSlice",
  initialState,
  reducers: {
    incrementPage: (state, action) => {
      state.page++;
    },
  },
  extraReducers: (builder) => {
    // new feed filter is set in userConfigSlice => reset feedlist and page values for new feed
    builder.addCase(setFeedFilter, (state) => {
      state.feedList = [];
      state.page = 1;
    });
    builder.addCase(feed.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(feed.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.paginationInfo = payload.pagination;
      if (state.page > 1) {
        state.feedList = [...state.feedList, ...payload.data];
      } else {
        state.feedList = payload.data;
      }
    });
    builder.addCase(feed.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder
      .addCase(fetchFeedTags.pending, (state, action) => {
        state.tagsStatus = ApiStatus.Pending;
      })
      .addCase(fetchFeedTags.fulfilled, (state, action) => {
        state.tagsStatus = ApiStatus.Fulfilled;
        state.tags = action.payload;
      })
      .addCase(fetchFeedTags.rejected, (state, action) => {
        state.tagsStatus = ApiStatus.Rejected;
      });
  },
});
export const { incrementPage } = feedSlice.actions;
export const feedSliceReducer = feedSlice.reducer;
export default feedSliceReducer;
