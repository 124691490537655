import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSelector } from "react-redux";

const notesTextStyle = {
  fontWeight: 400,
  fontFamily: "Lato",
  fontSize: "16px",
  lineHeight: "24px",
};
const notesTextBold = {
  fontWeight: 700,
};

const FeedHighlights = ({ feedDataList }) => {
  const mainHighlight = feedDataList && feedDataList?.highlight[0];
  const highlights =
    feedDataList?.description?.length > 0
      ? feedDataList.description
      : [mainHighlight];

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  return (
    <>
      {highlights &&
        highlights?.length > 0 &&
        highlights?.map((highlight, index) => (
          <ListItem
            key={index}
            sx={{
              padding: "0px",
              paddingTop: index === 0 ? "0px" : "20px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "16px",
                paddingRight: "8px",
                paddingTop: "8px",
              }}
            >
              <FiberManualRecordIcon style={{ color: "#F60", fontSize: 24 }} />
            </ListItemIcon>
            <ListItemText>
              <span
                style={{
                  ...notesTextStyle,
                  ...(mainHighlight !== null &&
                    mainHighlight?.start > 0 &&
                    highlight?.start === mainHighlight?.start &&
                    notesTextBold),
                }}
                className="textBlack"
              >
                {highlight?.context}
              </span>
            </ListItemText>
          </ListItem>
        ))}
    </>
  );
};

export default FeedHighlights;
