/* eslint-disable default-case */
import axios from "axios";

import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import SmsIcon from "@mui/icons-material/Sms";
import CampaignIcon from "@mui/icons-material/Campaign";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

import { getApiUrl } from "./envVars";
import { getHeaders } from "../Utils/Features/authSlice";

const labelIconStyleInitial = {
  backgroundColor: "var(--primaryColor)",
  borderRadius: "33px",
  color: "white",
  padding: "3px",
};
export const fetchIdeacardIcons = () => {
  const apiRoot = getApiUrl();
  axios
    .get(`${apiRoot}/api/idea-label/index`, getHeaders())
    .then((res) => {
      localStorage.setItem("ideacardIcons", JSON.stringify(res.data.data));
    })
    .catch((err) => {
      console.log("err", err);
    });
};
export const dynamicBulletHandler = (
  option,
  fontSizeRequested,
  iconStyleRequested
) => {
  const fontSize = fontSizeRequested || "large";
  let labelIconStyle = labelIconStyleInitial;
  if (iconStyleRequested) {
    labelIconStyle = iconStyleRequested;
  }
  switch (option) {
    case "KEYWORDS":
      return <VpnKeyIcon fontSize={fontSize} sx={labelIconStyle} />;
    case "MAIN CLAIMS":
      return <DoubleArrowIcon fontSize={fontSize} sx={labelIconStyle} />;
    case "QUOTES":
      return <FormatQuoteIcon fontSize={fontSize} sx={labelIconStyle} />;
    case "EXAMPLES":
      return <SmsIcon fontSize={fontSize} sx={labelIconStyle} />;
    case "ACTIONS ITEMS":
      return <CampaignIcon fontSize={fontSize} sx={labelIconStyle} />;
    case "custom1":
      return <SettingsRoundedIcon fontSize={fontSize} sx={labelIconStyle} />;
    case "custom2":
      return <SettingsRoundedIcon fontSize={fontSize} sx={labelIconStyle} />;
    case "custom3":
      return <SettingsRoundedIcon fontSize={fontSize} sx={labelIconStyle} />;
  }
};

export const getIdeacardIcons = (
  label_id,
  fontSize = "small",
  fontStyle = labelIconStyleInitial
) => {
  const allIcons = JSON.parse(localStorage.getItem("ideacardIcons"));
  if (!label_id) {
    label_id = allIcons[0]._id;
  }
  if (allIcons) {
    const filteredIcon = allIcons.filter((item) => item._id === label_id);
    if (filteredIcon && filteredIcon[0]) {
      return dynamicBulletHandler(filteredIcon[0].label, fontSize, fontStyle);
    }
  }
};
export const getLabelId = (keyword = "KEYWORDS") => {
  const allIcons = JSON.parse(localStorage.getItem("ideacardIcons"));
  // console.log("keyword", keyword);

  if (allIcons) {
    const filteredLabelId = allIcons.filter((item) => item.label === keyword);
    // console.log("filteredLabelId", filteredLabelId);
    if (filteredLabelId) {
      return filteredLabelId[0]._id;
    }
  }
};
export default fetchIdeacardIcons;
