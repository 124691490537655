import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSelector } from "react-redux";

const notesTextStyle = {
  fontWeight: 400,
  fontFamily: "Gaegu, cursive",
  fontSize: "21px",
  lineHeight: "32px",
};

const FeedNotes = ({ feedDataList }) => {
  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  const notesArray = feedDataList?.my_notes;
  return (
    <>
      {notesArray &&
        notesArray.length > 0 &&
        notesArray?.map((data, index) => (
          <ListItem
            key={index}
            sx={{
              padding: "0px",
              paddingTop: index === 0 ? "0px" : "20px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "16px",
                paddingRight: "8px",
                paddingTop: "8px",
              }}
            >
              <FiberManualRecordIcon style={{ color: "#F60", fontSize: 24 }} />
            </ListItemIcon>
            <ListItemText>
              <span
                style={notesTextStyle}
                className={`background-shadow-${lightDarkMode} textBlack`}
              >
                {data.content}
              </span>
            </ListItemText>
          </ListItem>
        ))}
    </>
  );
};

export default FeedNotes;
