import "./App.css";
import "./colorTheme.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";

import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";

import MyLibrary from "./pages/MyLibrary/MyLibrary";
import ViewTabMenu from "./pages/ViewTabMenu/ViewTabMenu";
import Sidebar, { menuItems } from "./components/Sidebar/Sidebar";

import {
  AuthenticationStatus,
  login,
  subscriptionChecked,
} from "./Utils/Features/authSlice";
import { Loading } from "./components/Loading";
import { customLog } from "./helperFunctions/customLogger";
import ExtensionInstallationPrompt from "./components/Connectors/ExtensionInstallationPrompt";
import AmplifyLoginSignupUI from "./pages/Login/AmplifyLoginSignup";
import MyFeed from "./pages/MyFeed/MyFeed";
import { selectIdeaCard } from "./Utils/Features/librarySlice";

import { updatePersistentDrawer } from "./Utils/Features/persistentDrawerSlice";
import { updateIdentifyIdeaCardData } from "./Utils/Features/IdentifyIdeaCardSlice";

import { fetchIdeacardIcons } from "./helperFunctions/getIdeacardIcons";
import ChromeExtensionConnector from "./components/Connectors/ChromeExtensionCommunication";
import { restartSyncProcess } from "./Utils/Features/amazonSyncSlice";
import { SyncOrigin } from "./components/Connectors/ChromeExtensionCommunication";
import Bottombar from "./components/Sidebar/Bottombar";
import Profile from "./components/ProfileTabs/Profile-tabs";
import { History } from "./helperFunctions/global";
import { Logout } from "./components/Logout/Logout";
import { applyColorTheme } from "./helperFunctions/colorThemes.js";
import {
  Pages,
  setCurrentPageTabData,
  setShowFilter,
} from "./Utils/Features/currentPageTab";
import { getCurrentEnvironment } from "./helperFunctions/envVars.js";

// DO not remove this.. needs it for global API error handling
import { axiosInterceptor } from "./helperFunctions/global"; // eslint-disable-line
import toastMessage, { toastType } from "./helperFunctions/toastMessage.js";
import SuccessPage from "./pages/Success/SuccessPage";
import CancelPage from "./pages/Cancel/CancelPage";
import News from "./pages/News/News";
import { hasIntervalPassed } from "./helperFunctions/timing";
import { fetchPostsByCategory } from "./Utils/Features/wordpressSlice.js";
import { setNewsLastChecked } from "./Utils/Features/userConfigSlice.js";

// configure amplify for multiple redirects
let env = getCurrentEnvironment();

// "redirectSignIn": "http://localhost:3000/,https://app.deepread.com,https://frontend.deepread.com,https://dev.deepread.com,https://deepread.demo1.bytestechnolab.com/",
let [
  localRedirectSignIn, // http://localhost:3000
  prodRedirectSignIn, // https://app.deepread.com
  oldProdRedirectSignIn, // https://frontend.deepread.com
  newStagingRedirectSignIn, // https://dev.deepread.com
  bytesLabIn, // https://deepread.demo1.bytestechnolab.com/
] = awsconfig.oauth.redirectSignIn.split(",");
let [
  localRedirectSignOut,
  prodRedirectSignOut,
  oldProdRedirectSignOut,
  newStagingRedirectSignOut,
  bytesLabOut,
] = awsconfig.oauth.redirectSignOut.split(",");
customLog(
  `Redirection links: local: ${localRedirectSignIn}, prod: ${prodRedirectSignIn}, NEW staging: ${newStagingRedirectSignIn}, bytesLabIn: ${bytesLabIn}, OLD prod: ${oldProdRedirectSignIn}`
);

let redirectSignIn, redirectSignOut;

switch (env) {
  case "prod":
    redirectSignIn = prodRedirectSignIn;
    redirectSignOut = prodRedirectSignOut;
    break;
  case "staging":
    redirectSignIn = newStagingRedirectSignIn; //stagingRedirectSignIn;
    redirectSignOut = newStagingRedirectSignOut; //stagingRedirectSignOut;
    break;
  case "demo":
    // Add demo redirect links here
    redirectSignIn = bytesLabIn;
    redirectSignOut = bytesLabOut;
    break;
  case "dev":
    redirectSignIn = localRedirectSignIn;
    redirectSignOut = localRedirectSignOut;
    break;
  default:
    redirectSignIn = localRedirectSignIn;
    redirectSignOut = localRedirectSignOut;
    break;
}

// TODO when domain change, add the new newStagingRedirectSignIn/Out to the config
const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn,
    redirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

function App() {
  History.navigate = useNavigate();
  const dispatch = useDispatch();
  const dataType = useSelector((state) => state.persistentDrawerReducer.value);

  // System theme preference  TODO: how do we use this?
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  const newsLastChecked = useSelector(
    (state) => state?.userConfig?.newsLastChecked
  );

  const newsCheck = () => {
    if (newsLastChecked === null || hasIntervalPassed(newsLastChecked, 24, 0)) {
      dispatch(fetchPostsByCategory("News"));
      dispatch(setNewsLastChecked());
    }
  };

  useEffect(() => {
    newsCheck();
  }, []);

  // Theme configuration
  const theme =
    lightDarkMode === "dark"
      ? createTheme({
          typography: {
            fontFamily: ["Lato", "sans-serif"].join(","),
            fontColor: "var(--fontColor)",
          },
          palette: {
            mode: "dark",
            background: {
              default: "var(--backgroundBlackFirst)",
            },
          },
          components: {
            MuiInputBase: {
              styleOverrides: {
                input: {
                  color: "var(--white)",
                },
              },
            },
            MuiPopper: {
              styleOverrides: {
                root: {
                  "& .MuiPaper-root": {
                    backgroundColor: "var(--BackgroundColor)",
                    boxShadow: "5px 10px 10px var(--shadowColor)",
                    marginLeft: "10px",
                  },
                },
              },
            },
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  backgroundColor: "var(--backgroundBlackThird)",
                  color: "var(--white)",
                  borderRadius: "8px",
                  padding: "5px 10px",
                  // TODO: fix to use css variables:
                  fontSize: ".875rem",
                  lineHeight: "1.25rem",
                },
                arrow: {
                  color: "var(--backgroundBlackThird)",
                },
              },
            },
            MuiCheckbox: {
              styleOverrides: {
                root: {
                  color: "var(--primaryColor)",
                  "&.Mui-checked": {
                    color: "var(--primaryColor)",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 25 },
                },
              },
            },
            MuiMenu: {
              styleOverrides: {
                list: {
                  border: "1px solid var(--borderColors)",
                  borderRadius: "6px",
                },
              },
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  borderRadius: "12px",
                  boxShadow: "none",
                  borderWidth: "1px",
                  borderColor: "var(--borderColors)",
                  backgroundColor: "var(--cardBackgroundColor)",
                  backgroundImage: "none",
                  color: "var(--fontColor)",
                  gap: "12px",
                  margin: "3px",
                  marginBottom: "20px",
                },
              },
            },
            MuiTypography: {
              styleOverrides: {
                root: {
                  color: "var(--fontColor)",
                },
              },
            },
          },
        })
      : createTheme({
          typography: {
            fontFamily: ["Lato", "sans-serif"].join(","),
            fontColor: "var(--fontColor)",
          },
          palette: {
            mode: "light",
            background: {
              default: "var(--BackgroundColor)",
            },
          },
          components: {
            MuiInputBase: {
              styleOverrides: {
                input: {
                  color: "var(--fontColor)",
                },
              },
            },
            MuiPopper: {
              styleOverrides: {
                root: {
                  "& .MuiPaper-root": {
                    boxShadow: "5px 10px 10px var(--greyColor)",
                    marginLeft: "10px",
                  },
                },
              },
            },
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  backgroundColor: "var(--ClickState)",
                  color: "var(--dark)",
                  fontSize: ".875rem",
                  lineHeight: "1.25rem",
                  borderRadius: "8px",
                  padding: "5px 10px",
                },
                arrow: {
                  color: "var(--ClickState)",
                },
              },
            },
            MuiCheckbox: {
              styleOverrides: {
                root: {
                  color: "var(--primaryColor)",
                  "&.Mui-checked": {
                    color: "var(--primaryColor)",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 25 },
                },
              },
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  borderRadius: "9px",
                  boxShadow: "none",
                  borderWidth: "1px",
                  borderColor: "var(--borderColors)",
                  backgroundColor: "var(--cardBackgroundColor)",
                  backgroundImage: "none",
                  color: "var(--fontColor)",
                  gap: "12px",
                  margin: "3px",
                  marginBottom: "20px",
                },
              },
            },
            MuiTypography: {
              styleOverrides: {
                root: {
                  color: "var(--fontColor)",
                },
              },
            },
          },
        });

  useEffect(() => {
    if (lightDarkMode === "light") {
      document.body.style.background = "var(--BackgroundColor)";
    } else if (lightDarkMode === "dark") {
      document.body.style.background = "var(--backgroundBlackFirst)";
    }
    // TODO: eventually we can remove the css color variables from App.css :root
    // when all the css supports this new method of defining and applying them
    // in colorThemes.js
    applyColorTheme(lightDarkMode);
  }, [lightDarkMode]);

  const navigate = useNavigate();

  const { authenticationStatus, userData } = useSelector((state) => state.auth);

  const { restartSync, syncType } = useSelector((state) => state.amazonSync);

  const [, setUser] = useState(null);
  const [, setCustomState] = useState(null);

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    // close the filter always when using url routing,
    // by default is anyway closed when view/page is changed
    // TODO: rethink how to do this filters mess better
    if (isMdUp) {
      dispatch(setShowFilter(false));
    }

    // Check for the root URL and redirect to /library (in the main case) so we don't start with empty page
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/index.html"
    ) {
      if (restartSync) {
        // in case we left the app for the amazon authentication (federatedSignIn)
        // redirect back to page where amazon sync was initiated before stepping out for authentication
        if (syncType?.originView === SyncOrigin.Book) {
          navigate("/library", { replace: true });
        } else if (syncType?.originView === SyncOrigin.Highlight) {
          navigate("/views", { replace: true });
        }
      } else {
        navigate("/library", { replace: true });
      }
    } else if (isMdUp) {
      // set the correct currentPageTab for drawer to
      // know which filter open after url routing is used
      // TODO: rethink how to do this filters mess better
      menuItems.forEach((item) => {
        if (item.path === window.location.pathname) {
          if (item.path === "/views") {
            // list view/filter is the default book view:
            dispatch(setCurrentPageTabData(Pages.ListView));
          } else {
            dispatch(setCurrentPageTabData(item.page));
          }
        }
      });
    }
  });

  useEffect(() => {
    if (
      authenticationStatus === AuthenticationStatus.Authenticated &&
      !localStorage.getItem("ideacardIcons")
    ) {
      fetchIdeacardIcons();
    }
  }, [authenticationStatus]);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case "signIn":
        case "signUp":
          setUser(data);
          window.location.reload(); // this seems to do the trick, for manual login, triggers a refresh + redirect
          customLog("signIn || signUp: and RELOAD login: ", event, data);
          break;
        case "signOut":
          setUser(null);
          customLog("signOut - login: ", event, data);
          break;
        case "oAuthSignOut":
          setUser(null);
          customLog("oAuthSignOut: ", event, data);
          break;
        case "customOAuthState":
          setCustomState(data);
          customLog("customOAuthState - login: ", event, data);
          break;
        default:
          customLog("default - login: ", event, data);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        customLog("authenticationStatus: ", authenticationStatus);
        if (authenticationStatus === AuthenticationStatus.NotAuthenticated) {
          Auth.currentUserInfo().then((currUserInfo) => {
            customLog("Real User Creation Procedure invoked");
            const body = {
              name: currUserInfo.username,
              customer_id: currUserInfo.username,
              email: currUserInfo.attributes.email,
            };

            dispatch(login({ dispatch, body }));
          });
        } else {
          if (restartSync) {
            dispatch(restartSyncProcess(false));
            if (currentUser.username.includes("amazon")) {
              ChromeExtensionConnector.restartAmazonSync();
            } else {
              ChromeExtensionConnector.showToastError(
                "Amazon authentication failed. Please try again later."
              );
            }
          }
        }
      })
      .catch((error) => customLog("Not signed in: " + error));

    // for the event handling of the amplify login
    return unsubscribe;
    // we want this effect to run only on initial render:
    // [restartSync, dispatch]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerClose = (e) => {
    e.stopPropagation();
    if (dataType === "ideaCard") {
      dispatch(selectIdeaCard(null));
      dispatch(updatePersistentDrawer(null));
      dispatch(updateIdentifyIdeaCardData(null));
    }
  };

  const checkSubscriptionStatus = (userData) => {
    if (userData?.profile?.trialStarted) {
      toastMessage(
        toastType.INFO,
        false,
        <div>
          🎉 Welcome aboard! 🎉
          <br />
          <br />
          We're excited to have you join us! Your{" "}
          {userData.profile.trialDaysTotal}-day free trial starts now, so dive
          in and enjoy our features.
          <br />
          <br />
          You can subscribe anytime via the Profile/Billing page to keep your
          content private. After {userData.profile.trialDaysTotal} days, you’ll
          switch to the free plan, where all your content will be public.
          <br />
          <br />
          For any questions, our support team is here to help. Happy exploring,
          and welcome to our app family!
          <br />
          <br />
          Best wishes,
          <br />
          The DeepRead Team 🌟
        </div>
      );
    } else if (userData?.profile?.trialEnded) {
      toastMessage(
        toastType.INFO,
        false,
        <div>
          Your {userData.profile.trialDaysTotal}-day free trial has ended, and
          you’re now on the free subscription. We trust you made the most of
          your free trial. ✨
          <br />
          <br />
          You can still use DeepRead, but all your content will be public. To
          keep your content private, subscribe via the Profile &lt; Billing
          page.
          <br />
          <br />
          For questions or assistance, our support team is here for you. We hope
          you continue enjoying the app!
          <br />
          <br />
          Best wishes,
          <br />
          The DeepRead Team 🌟
        </div>
      );
    }
    dispatch(subscriptionChecked());
  };

  switch (authenticationStatus) {
    case AuthenticationStatus.Pending:
      return <Loading />;
    case AuthenticationStatus.Failed:
      return (
        <Logout>
          <div>
            Hey there!
            <br />
            <br />
            Uh-oh! It looks like there's been a little hiccup with your
            authentication. 😅
            <br />
            <br />
            No sweat, though! Let's try that one more time. If the issue
            persists, just let us know, and we'll lend a hand to sort it out.
            Thanks for your understanding and patience!
            <br />
            <br />
            Best regards,
            <br />
            DeepRead Team
          </div>
        </Logout>
      );
    case AuthenticationStatus.Expired:
      return (
        <Logout>
          <div>
            Hello!
            <br />
            <br />
            Oopsie! It seems your authentication has expired. 🕒
            <br />
            <br />
            Don't worry, it happens! Let's refresh that and get you back on
            track. If you need any assistance along the way, just give us a
            shout, and we'll be right here to help. Thanks for rolling with the
            punches!
            <br />
            <br />
            Warm regards,
            <br />
            DeepRead Team
          </div>
        </Logout>
      );
    case AuthenticationStatus.Authenticated:
      checkSubscriptionStatus(userData);
      return (
        <>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="rootContainer" onClick={handleDrawerClose}>
              {isMdUp && <Sidebar />}
              <Routes>
                <Route path="/views" element={<ViewTabMenu />} />
                <Route path="/library" element={<MyLibrary />} />
                <Route path="/feed" element={<MyFeed />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/cancel" element={<CancelPage />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/notices" element={<News />} />
                <Route path="*" element={<> not found</>} />
              </Routes>
              {!isMdUp && <Bottombar />}
            </div>
          </ThemeProvider>

          <ToastContainer />

          {/* Extension installation prompt */}
          <ExtensionInstallationPrompt />
        </>
      );
    default:
      // adds layout to the login page, so that it looks more like a DeepRead login ;)
      // eslint-disable-next-line no-lone-blocks
      {
        /* UI for Login */
      }
      return <AmplifyLoginSignupUI />;
  }
}

export default App;
