import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Stack from "@mui/material/Stack";
import SquareIcon from "@mui/icons-material/Square";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Tooltip from "@mui/material/Tooltip";

import { CardStructureBook, ChaptersUl, ChaptersLi } from "./styled";
import "./ListView.css";

import PersistentDrawerRight from "../../components/Drawer/Drawer";
import {
  CreateIdeaDialog,
  addTextSelection,
  cleanupTextSelection,
} from "../../helperFunctions/useTextSelection";
import { waitForElementToExist } from "../../helperFunctions/domHelpers";

import TriangleRight, {
  TriangleRightOutlined,
} from "../../Assets/triangleRight";

import { updateLevelCounter } from "../../Utils/Features/levelCounterSlice";

import {
  getCurrentIdeaCardId,
  getCurrentBook,
  fetchAllTags,
  updateChapterTags,
  fetchListViewData,
} from "../../Utils/Features/librarySlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus";
import { Loading } from "../../components/Loading";
import { IdeaCard } from "../../components/Views/IdeaCard";
import { Topics } from "../../components/Insights/Topics";
import { useIsMounted } from "../../helperFunctions/useIsMounted";
import highlightTester from "../../helperFunctions/highlightTester";
import { collectSelectedIdeas, collectSelectedLevels } from "../ListViewMenu";
import { isDevelopment } from "../../helperFunctions/envVars";

// styling
const sub_chapter_divs = {
  display: "flex",
};

function ListView(props) {
  const dispatch = useDispatch();
  // listViewData = content = chapters+higlights+ideacards
  const listViewData = useSelector((state) => state.library.listViewData);
  const openBook = useSelector((state) => getCurrentBook(state));
  const openIdeaCardId = useSelector((state) => getCurrentIdeaCardId(state));
  const viewStatus = useSelector((state) => state.library.viewStatus);
  const viewError = useSelector((state) => state.library.viewError);
  const allTags = useSelector((state) => state.library.chapterTags);
  const allTagsStatus = useSelector(
    (state) => state.library.allTagsStatus
  );

  const { listViewFilter: filter } = useSelector((state) => state.userConfig);

  const [tagsBeingAddedToLabel, setTagsBeingAddedToLabel] = useState("");

  const [createIdeaCardDialogOpen, setCreateIdeaCardDialogOpen] =
    useState(false);
  const textSelectableContentRef = useRef(null);

  useEffect(() => {
    const liChilds = document.querySelectorAll(".highlightLi");

    if (highlightTester()) {
      for (var i = 0; i < liChilds.length; ++i) {
        let item = liChilds[i].classList;
        if (item) {
          if (filter?.showHighlights) {
            liChilds[i].classList.remove("d-none");
          } else {
            liChilds[i].classList.add("d-none");
          }
        }
      }
    }
  }, [filter?.showHighlights]);

  useEffect(() => {
    if (filter?.showIdeacards)
      collectSelectedIdeas(Object.values(filter.showIdeacards));
  }, [filter?.showIdeacards]);

  useEffect(() => {
    if (filter?.showChapters) collectSelectedLevels(filter.showChapters);
  }, [filter?.showChapters]);

  useEffect(() => {
    if (!listViewData && openBook) {
      dispatch(fetchListViewData(openBook?._id));
    }
  }, [openBook, dispatch, listViewData]);

  useEffect(() => {
    setMaxCount(0);
    if (!listViewData?.read_only) {
      addTextSelection(textSelectableContentRef, setCreateIdeaCardDialogOpen);
    }

    return () => {
      if (!listViewData?.read_only) {
        cleanupTextSelection(textSelectableContentRef);
      }
    };
  }, [listViewData]);

  // used for ideacarddivcomponent as props
  const [maxCount, setMaxCount] = useState(1);
  let levelCount = 1;

  const callForLevelCounter = (levelCounter) => {
    if (levelCounter > maxCount) {
      setMaxCount(levelCounter);
    }
  };

  useEffect(() => {
    if (allTagsStatus === ApiStatus.NotRun) {
      dispatch(fetchAllTags());
    }
  }, [dispatch, allTagsStatus, allTags]);

  /* FUNCTION RECURSIVE TO SHOW ALL SUBCHAPTERS */
  const getContentRecursive = (item, levelCount) => {
    callForLevelCounter(levelCount);
    return (
      <>
        {item.entries?.length ? (
          <ChaptersUl className="d-none">
            {item.entries.map((chapter, i) => {
              return (
                <ChaptersLi
                  key={chapter.tocPositionId}
                  id={`chapters-${chapter.tocPositionId}`}
                >
                  <div
                    className={`gap-x-[3px] ${
                      chapter.entries || chapter.highlights?.length
                        ? `caret level-${levelCount}`
                        : `caret-without-content  level-${levelCount}`
                    }`}
                    style={sub_chapter_divs}
                    id={`caret-${chapter.tocPositionId}`}
                    onClick={(e) => clickHandler(e, chapter.tocPositionId)}
                  >
                    <span className="pt-[9px] icon">
                      <TriangleRight
                        sx={{
                          "& svg": {
                            color: "red",
                            background: "red",
                          },
                        }}
                      />{" "}
                    </span>
                    <span>
                      <TriangleRightOutlined
                        // fontSize="small"
                        id="lastItemDot"
                      />
                    </span>

                    <div
                      className={`flex flex-wrap items-center gap-x-[2px] min-h-[36px] textBlack`}
                    >
                      <span className="ellipsisStyling">
                        {chapter.label || ""} {chapter?._id}
                      </span>

                      <Topics
                        // listviewData = content = chapters+higlights+ideacards
                        id={listViewData?._id}
                        type="chapter"
                        updateHandler={updateHandler}
                        tagsBeingAddedToLabel={tagsBeingAddedToLabel}
                        setTagsBeingAddedToLabel={setTagsBeingAddedToLabel}
                        currentLabel={chapter.label}
                      />
                    </div>
                  </div>
                  {getContentRecursive(chapter, levelCount + 1)}
                </ChaptersLi>
              );
            })}
          </ChaptersUl>
        ) : null}
        {item.highlights?.length ? (
          <ChaptersUl className="d-none highlightUl">
            {item.highlights.map((highlight, i) => {
              return (
                <div key={highlight._id}>
                  {highlight.idea_cards?.length
                    ? highlight.idea_cards.map((ideacard, index) => {
                        if (
                          ideacard?.title?.length > 0 &&
                          ideacard?._id &&
                          ideacard?.title?.trim() !== ""
                        ) {
                          return (
                            <IdeaCard
                              ideaCardId={ideacard._id}
                              key={ideacard._id}
                            />
                          );
                        } else return null;
                      })
                    : null}
                  {highlight.context ? (
                    <ChaptersLi
                      key={highlight._id}
                      id={`highlight-${highlight.position}`}
                      className={`highlightLi`}
                    >
                      <div className={`highlightDiv`}>
                        <SquareIcon fontSize={"small"} className="icon" />
                        <span
                          data-start={highlight.start}
                          data-book_id={highlight.book_id}
                          data-user_id={highlight.user_id}
                          id={highlight._id}
                          className={
                            !listViewData?.read_only &&
                            "highlightSpan customCursor"
                          }
                          style={{
                            "::selection": { backgroundColor: "#FFDAC1" },
                          }}
                        >
                          {highlight.context}

                          {highlight[isDevelopment() ? "context" : "note"] && (
                            <span className="text-sm notesWrapper ml-2 mt-[4px]">
                              <Tooltip
                                title={
                                  highlight[
                                    isDevelopment() ? "context" : "note"
                                  ]
                                }
                                className="text-sm"
                                arrow
                              >
                                <SpeakerNotesIcon
                                  sx={{
                                    fontSize: "17px",
                                    color: "var(--fontLightColor)",
                                  }}
                                />
                              </Tooltip>
                            </span>
                          )}
                        </span>
                      </div>
                    </ChaptersLi>
                  ) : null}
                </div>
              );
            })}
          </ChaptersUl>
        ) : null}
      </>
    );
  };
  const arrowOpenCloseHandler = (elementItself) => {
    if (!elementItself) return;
    if (
      !elementItself.classList.value.includes("caret-without-content-outer") &&
      !elementItself.classList.value.includes("caret-without-content")
    ) {
      if (elementItself.classList.value.includes("caret-down-45")) {
        elementItself.classList.remove("caret-down-45");
      } else if (!elementItself.classList.value.includes("caret-down-45")) {
        elementItself.classList.add("caret-down-45");
      }
    }
  };
  const displayNoneHandler = (ulChilds, doubleClickAction) => {
    if (ulChilds.length) {
      for (var i = 0; i < ulChilds.length; i++) {
        let item = ulChilds[i].classList;
        if (item) {
          if (item.value.includes("d-none")) {
            ulChilds[i].classList.remove("d-none");
          } else {
            ulChilds[i].classList.add("d-none");
          }
        }
        if (doubleClickAction) {
          let liChilds = ulChilds[i].childNodes;
          for (var j = 0; j < liChilds.length; j++) {
            let item = liChilds[j].id;
            doubleClickOpenOrCloseChapters(item.split("chapters-")[1]);
          }
        }
      }
    }
  };
  /* FUNCTION TO OPEN OR CLOSE SUBCHAPTERS */

  const openOrCloseChapters = (index, doubleClickAction) => {
    const element = document.querySelectorAll(`#chapters-${index} > ul`);
    const el = document.querySelector(`#caret-${index}`);
    arrowOpenCloseHandler(el);
    displayNoneHandler(element, doubleClickAction);
  };
  const doubleClickOpenOrCloseChapters = (index) => {
    const ulChilds = document.querySelector(`#chapters-${index} > ul`);
    if (ulChilds) {
      openOrCloseChapters(index, false);
      const childNodes = ulChilds.childNodes;
      for (var i = 0; i < childNodes.length; i++) {
        let item = childNodes[i].id;
        if (item.startsWith("chapters-")) {
          openOrCloseChapters(item.split("chapters-")[1], true);
        }
      }
    } else {
      return;
    }
  };

  const clickHandler = (event, index) => {
    if (event.ctrlKey) {
      // Perform your desired function here

      doubleClickOpenOrCloseChapters(index);
    } else {
      openOrCloseChapters(index, false);
    }
  };

  useEffect(() => {
    dispatch(updateLevelCounter(maxCount));
  }, [maxCount, dispatch]);

  const updateHandler = (_, newData) => {
    // listviewData = content = chapters+higlights+ideacards
    const id = listViewData?._id;

    dispatch(
      updateChapterTags({
        contentId: id,
        newData: { label: tagsBeingAddedToLabel, tags: newData.tags.sort() },
      })
    );
  };

  return (
    <div
      style={{ alignItems: !openIdeaCardId ? "center" : "start" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {!listViewData?.read_only && (
        <CreateIdeaDialog
          open={createIdeaCardDialogOpen}
          handleClose={() => setCreateIdeaCardDialogOpen(false)}
        />
      )}
      {!openBook ? (
        <>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            sx={{
              height: "78vh",
              textAlign: "center",
            }}
          >
            <h3 className="textBlack">
              Oops! You have not selected a book yet!
            </h3>
            <h4 className="textBlack">
              select a book first, please. <br />
              😊
            </h4>
          </Stack>
        </>
      ) : viewStatus === ApiStatus.Fulfilled ? (
        <div className="w-full">
          <PersistentDrawerRight
            childrenx={
              <div
                ref={textSelectableContentRef}
                style={{
                  width: "100%",
                  position: "relative",
                  height: "100%",
                  display: "flex",
                }}
              >
                <CardStructureBook className="listViewParent listViewWrapper relative">
                  {listViewData?.data?.length ? (
                    <ChaptersUl style={{ margin: "0", border: "none" }}>
                      {listViewData?.data?.map((item, index) => {
                        return (
                          <ChaptersLi key={index} id={`chapters-${index}`}>
                            <div
                              className={`${
                                item.entries || item.highlights?.length
                                  ? `caret level-${levelCount}`
                                  : `caret-without-content-outer level-${levelCount}`
                              }`}
                              id={`caret-${index}`}
                              style={{
                                display: "flex",
                                gap: "2px",
                                alignItems: "flex-start",
                              }}
                              onClick={(e) => clickHandler(e, index)}
                              // onDoubleClick={() => doubleClickOpenOrCloseChapters(index)}
                            >
                              <span className="pt-[9px] icon">
                                <TriangleRight
                                  sx={{
                                    "& polygon": {
                                      color: "red",
                                      background: "red",
                                      fill: "red !important",
                                    },
                                  }}
                                  className=""
                                />
                              </span>
                              <span className="pt-[5px]">
                                <TriangleRightOutlined
                                  // fontSize="small"
                                  id="lastItemDot"
                                />
                              </span>

                              <div className="flex flex-wrap items-center gap-x-[2px] min-h-[36px]">
                                <span className="ellipsisStyling textBlack">
                                  {item.label || ""} {item?._id}
                                </span>
                                <Topics
                                  // listviewData = content = chapters+higlights+ideacards
                                  id={listViewData?._id}
                                  type="chapter"
                                  updateHandler={updateHandler}
                                  tagsBeingAddedToLabel={tagsBeingAddedToLabel}
                                  setTagsBeingAddedToLabel={
                                    setTagsBeingAddedToLabel
                                  }
                                  currentLabel={item.label}
                                />
                              </div>
                            </div>
                            {getContentRecursive(item, levelCount + 1)}
                          </ChaptersLi>
                        );
                      })}
                    </ChaptersUl>
                  ) : (
                    <Stack
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      spacing={2}
                      sx={{
                        height: "78vh",
                        textAlign: "center",
                      }}
                    >
                      <h3 className="textBlack">
                        Oops! There are no Highlights or Ideacards <br />
                        for this Particular Book
                      </h3>
                      <h4 className="textBlack">
                        Highlight your favorite passages and share your insights
                        with us <br />
                        😊
                      </h4>
                    </Stack>
                  )}
                </CardStructureBook>
                <div className={`flex justify-between relative  `}>
                  {/* <OpenCloseAllChapters /> */}
                  <div className="size" title="Drag to change width">
                    <OpenCloseAllChapters />
                  </div>
                </div>
              </div>
            }
          ></PersistentDrawerRight>
        </div>
      ) : viewStatus === ApiStatus.Pending ? (
        <Loading />
      ) : (
        <div title={viewError} className="textBlack">
          Loading View Data failed.
        </div>
      )}
    </div>
  );
}

const OpenCloseAllChapters = () => {
  useEffect(() => {
    // by default open all the chapters
    openCloseAllChapters();
    // we want this effect to run only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMounted = useIsMounted();
  const [showAllIcon, setShowAllIcon] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const openCloseAllChapters = () => {
    setShowAll(!showAll);

    // Function to handle the logic for each caretDiv
    const handleCaretDiv = (caretDiv) => {
      if (showAll) {
        caretDiv.classList.remove("caret-down-45");
      } else {
        caretDiv.classList.add("caret-down-45");
      }

      // Check if there is a next sibling element
      const ulElements = [];
      let nextElement = caretDiv.nextElementSibling;

      // Collect the next two UL elements (or fewer if there are less than two)
      while (ulElements.length < 2 && nextElement) {
        if (nextElement.tagName === "UL") {
          ulElements.push(nextElement);
        }
        nextElement = nextElement.nextElementSibling;
      }

      // Toggle the "d-none" class for each ulElement
      ulElements.forEach((ulElement) => {
        if (showAll) {
          ulElement.classList.add("d-none");
        } else {
          ulElement.classList.remove("d-none");
        }
      });
    };

    // Function to check if caret elements are present and then go for the toggle
    const toggleChapter = () => {
      waitForElementToExist(".caret").then((element) => {
        const caretDivs = document.querySelectorAll(".caret");
        caretDivs.forEach(handleCaretDiv);
        if (isMounted()) setShowAllIcon(!showAllIcon);
      });
    };

    // Initial check and apply toggle
    toggleChapter();
  };

  return (
    <div
      onClick={openCloseAllChapters}
      className={`openAndCloseAll w-[24px] h-[24px] absolute left-[-30px] md:left-[-90px] top-[12px] flex items-center justify-center  border rounded-full border-[#E5E5E4] `}
    >
      {showAllIcon ? (
        <span title="Close all chapters">
          <KeyboardDoubleArrowDownIcon className="iconStyle" />
        </span>
      ) : (
        <span title="Open all chapters" className="pb-[1px] pl-[2px]">
          <KeyboardDoubleArrowRightIcon className="iconStyle" />
        </span>
      )}
    </div>
  );
};

export default ListView;
