import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getIdeacardIcons } from "../../helperFunctions/getIdeacardIcons";
import { updatePersistentDrawer } from "../../Utils/Features/persistentDrawerSlice";
import { updateIdentifyIdeaCardData } from "../../Utils/Features/IdentifyIdeaCardSlice";

import {
  selectIdeaCard,
  getIdeaCardById,
  getCurrentIdeaCardId,
} from "../../Utils/Features/librarySlice";

import { handleDrawerClose } from "../Drawer/Drawer";
import { addBackButtonListener } from "../../helperFunctions/BackButtonHandler";
import { closeCreateIdeaDialog } from "../../helperFunctions/useTextSelection";

export const IdeaCard = ({ ideaCardId }) => {
  const ideaCard = useSelector((state) => getIdeaCardById(state, ideaCardId));
  const currentIdeaCardId = useSelector((state) => getCurrentIdeaCardId(state));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(updatePersistentDrawer(null));
    dispatch(updateIdentifyIdeaCardData(null));
    if (currentIdeaCardId !== ideaCard?._id) {
      dispatch(selectIdeaCard(ideaCard?._id));
      dispatch(updatePersistentDrawer("ideaCard"));

      if (!isMdUp) {
        // in case the ideacard is opened in overlay close the idea card
        // and navigate back to current location when back button is pressed
        // current location = the page open under the overlay
        addBackButtonListener(window.location.pathname, handleDrawerClose);
      } else {
        // in case the ideacard is opened in drawer still "close" the ideacard
        // but navigate with the normal back button procedure
        addBackButtonListener(null, handleDrawerClose);
      }
      // clearing identify data in case previous was identify idea card type
      dispatch(updateIdentifyIdeaCardData(null));
    } else {
      dispatch(selectIdeaCard(null));
      dispatch(updatePersistentDrawer(null));
      dispatch(updateIdentifyIdeaCardData(null));
    }
    closeCreateIdeaDialog();
  };

  return (
    <div
      className={`ideacardDiv ideacard-${ideaCard?.label_id} textBlack`}
      style={{
        border:
          currentIdeaCardId === ideaCard?._id
            ? "2px solid var(--primaryColor)"
            : "",
      }}
      onClick={clickHandler}
      aria-label="open drawer"
    >
      <span>{getIdeacardIcons(ideaCard?.label_id)}</span>
      <span>
        <b> {ideaCard?.title || ""}</b>
      </span>
    </div>
  );
};
