import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/AddCircle";

import { updateIdeaCardRelation } from "../../../Utils/Features/librarySlice";
import { getIdeacardIcons } from "../../../helperFunctions/getIdeacardIcons";

export const LinkIdeaCard = ({
  ideaCardToLinkId,
  resultCards,
  linkedCardsData,
  setLinkedCardsCallback,
}) => {
  const dispatch = useDispatch();
  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );
  const [cards, setCards] = useState(null);
  useEffect(() => {
    setCards(
      resultCards.filter(
        (card) =>
          linkedCardsData?.filter(
            (linkedCard) => linkedCard.idea_id === card._id
          ).length === 0
      )
    );
  }, [resultCards, linkedCardsData]);

  const handleAdd = (cardId, cardTitle) => {
    let linkedCardsDataTemp = Object.assign([], linkedCardsData);
    linkedCardsDataTemp.push({
      relation: "neutral",
      idea_id: cardId,
      title: cardTitle,
    });
    setLinkedCardsCallback(linkedCardsDataTemp);

    dispatch(
      updateIdeaCardRelation({
        ideaCardId: ideaCardToLinkId,
        newData: {
          linked_structure: linkedCardsDataTemp,
        },
      })
    );
    setCards([]);
  };
  return (
    <>
      {cards?.map((card, index) => {
        return (
          <div
            key={card._id}
            style={{
              display: "flex",
              gap: "6px",
              margin: "3px 0px",
              paddingLeft: "10px",
            }}
            onClick={() => handleAdd(card._id, card.title)}
          >
            <AddIcon style={{ marginTop: "-2px" }} />
            {getIdeacardIcons(card.label_id)}
            <span className="textBlack">{card.title}</span>
          </div>
        );
      })}
    </>
  );
};
